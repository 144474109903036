import { render, staticRenderFns } from "./LayoutTopBarAccountBtn.vue?vue&type=template&id=540a8963&"
import script from "./LayoutTopBarAccountBtn.vue?vue&type=script&lang=js&"
export * from "./LayoutTopBarAccountBtn.vue?vue&type=script&lang=js&"
import style0 from "./LayoutTopBarAccountBtn.vue?vue&type=style&index=0&id=540a8963&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/tmp/build_51aa5b7f/components/user/UserAvatar.vue').default,BaseIconNew: require('/tmp/build_51aa5b7f/components/base/BaseIconNew.vue').default,AccountEditModal: require('/tmp/build_51aa5b7f/components/account/profile/AccountEditModal.vue').default})
