import { EventBus } from '~/components/eventBus/EventBus';
import { UserBus } from '~/components/user/UsersBus';
import {
  decryptMessage,
  percentToHandleMeetingRequest,
  timeToHandleMeetingRequest,
} from '~/utils/functions';
import {
  ROOM_STATUS_IN_PROGRESS,
  PUBLIC_ROOMS,
  MESSAGES_STREAM_IN,
  MESSAGES_STREAM_OUT,
  STATUS_AVAILABLE,
  NOTIFICATIONS_PATH_BY_ACTIVITY,
} from '~/vars/api';
import { setOfferingProcess, setSellers } from '~/vars/store/actions';
import { SET_REQUESTED_ALL_SELLERS } from '~/vars/store/mutations';

const USER_HANDLERS = 'userHandlers';
const ROOM_HANDLERS = 'roomHandlers';
const MULTICAST_HANDLERS = 'multicastHandlers';

export default ({ app }) => ({
  /*
   *   PROPERTIES
   */
  socketsMsgsIds: [],
  channelsSubscribedIds: [],
  pingToServerInterval: null,
  /*
   *   GETTERS AND SETTERS
   */
  getSocketConnection() {
    return app.$socket.instance.getConnection();
  },
  unsetChannels() {
    const socketInstance = this.getSocketConnection();
    this.channelsSubscribedIds.forEach((chanelId) =>
      socketInstance.off(chanelId),
    );
    this.channelsSubscribedIds = [];
  },
  setChannels() {
    this.unsetChannels();
    const socketInstance = this.getSocketConnection();
    if (app.$auth.loggedIn) {
      const loggedUserId = app.$auth.user.data.id;
      socketInstance.on(
        loggedUserId,
        ((data) => {
          this.socketHandler(data, USER_HANDLERS);
        }).bind(this),
      );
      this.setIntervalToPingServer(socketInstance);
      this.channelsSubscribedIds.push(loggedUserId);
    }
    socketInstance.on(
      'multicast',
      ((data) => {
        this.socketHandler(data, MULTICAST_HANDLERS);
      }).bind(this),
    );
    //this.setRoomsChannels();
    this.channelsSubscribedIds.push('multicast');
    // Might be interesting to add a ping to udpate server user's last activitie field
    console.log('LOGGED CHANNELS', this.channelsSubscribedIds);
  },
  setIntervalToPingServer(socketInstance) {
    if (!this.pingToServerInterval) {
      this.pingToServerInterval = setInterval(() => {
        if (!app.$auth.loggedIn) {
          return clearInterval(this.pingToServerInterval);
        }
        socketInstance.emit('server', {
          type: 'ping',
          datestamp: new Date().getTime(),
          payload: 'PING',
          source: app.$auth.user.data.id,
        });
      }, 120000);
    }
  },
  setRoomsChannels() {
    // Subscribe to all rooms related with logged user
    app.store.getters['rooms/getAllPrivateRooms'].forEach(
      ({ roomid, title, createdat, starttime, roomstatus }) => {
        if (
          percentToHandleMeetingRequest(
            timeToHandleMeetingRequest(starttime),
            starttime,
            createdat,
          ) < 100 ||
          roomstatus === ROOM_STATUS_IN_PROGRESS
        ) {
          if (!this.channelsSubscribedIds.includes(roomid)) {
            this.channelsSubscribedIds.push(roomid);
            this.getSocketConnection().on(
              roomid,
              ((data) => this.socketHandler(data, ROOM_HANDLERS)).bind(this),
            );
          }
        }
      },
    );
  },
  /*
   *   METHODS
   */
  socketHandler({ type, id, payload }, socketType) {
    const handlers = {
      [USER_HANDLERS]: {
        onlineusers: this.setOnlineUsers,
        connmsg: this.onConnectionsUpdated,
        msg: this.OnReceiveMsg,
        roommsg: this.onRoomUpdated,
        participantmsg: this.onRoomUpdated,
        directpayment: this.onReceiveDirectPayment,
        newnotification: this.updateNotifications,
        socketconnection: this.socketconnection,
        updatemarketitem: this.updateMarketItem,
        market_ordercreated: this.updateMarketItem,
        market_orderpaid: this.notifyMarketPurchase,
        stripeupdate: this.checkStripe,
        '12wrequest.ack': this.appHashHandler,
        create_offering: this.createOffering,
        offeringstats: this.updateOfferings,
        profileupdate: this.profileupdated,
        'verify-identity': this.verifyIdentity,
      },
      [ROOM_HANDLERS]: {
        roommsg: this.onRoomUpdated,
        participantmsg: this.updateRoomParticipant,
        'room-status-changed': this.onRoomStatusChange,
        'room-hidden': this.onRoomHidden,
      },
      [MULTICAST_HANDLERS]: {
        roommsg: this.onRoomUpdated,
        onlineusers: this.updateOnlineUsers,
        'new-public-room': this.onNewPublicRoomCreated,
        'room-hidden': this.onRoomHidden,
        profileupdate: this.profileupdated,
        offeringstats: this.updateOfferings,
        updatemarketitem: this.updateMarketItem,
        newbid: this.updateBids,
      },
    };
    console.log(' ---- SOCKET TRIGGERED  ---- ');
    console.log('SOCKET', { type, payload, socketType });
    const handler = handlers[socketType][type];
    if (handler && !this.socketsMsgsIds.includes(id)) {
      handler.bind(this);
      this.socketsMsgsIds.push(id);
      handler(payload, id);
    }
  },

  /*
   * ROOM HANDLERS
   */
  onRoomStatusChange(room) {
    app.store.dispatch('rooms/updateRoomStatus', room);
  },
  onRoomHidden(room) {
    app.store.dispatch('rooms/hiddeRoom', room);
  },
  onRoomUpdated(room) {
    const loggedUserId = app.$auth.user.data.id;
    if (
      room.roomhostpresent &&
      room.roomhostid !== loggedUserId &&
      !app.router.currentRoute.path.includes('meeting')
    ) {
      this.onRoomStatusChange({
        ...room,
        ...{ roomstatus: ROOM_STATUS_IN_PROGRESS },
      });
      app.$toast.show(`Room host is present`, {
        icon: 'account-multiple',
        action: {
          text: 'Go to room',
          onClick: () => {
            const route = Object.assign(
              { query: { room: room.roomid } },
              NOTIFICATIONS_PATH_BY_ACTIVITY['room_private'],
            );
            app.router.push(app.localePath(route));
          },
        },
      });
    }
    if (room && room.title && room.roomid) {
      app.store.dispatch('rooms/updateRoom', room);
      if (
        (app.$auth.user.data.is_online === true ||
          app.$auth.user.data.is_online === STATUS_AVAILABLE) &&
        room.now
      ) {
        app.store.dispatch('rooms/addNowRoom', room);
      }
    }
  },
  onNewPublicRoomCreated(room) {
    if (room.roomhostid !== app.$auth.user.data.id) {
      app.store.commit('rooms/ADD_ROOM', { type: PUBLIC_ROOMS, room });
    }
  },
  updateRoomParticipant(participant) {
    // If receive room
    if (participant.title && participant.roomid) {
      return this.onRoomUpdated(participant);
    }
    app.store.dispatch('rooms/updateParticipantStatus', participant);
  },
  /*
   * USER CHANNEL HANDLERS
   */
  onReceiveDirectPayment({ sourcenick }) {
    app.$toast.show(
      app.i18n.t('sidebar.section.direct.description.toast', {
        usernick: sourcenick,
      }),
      {
        icon: 'currency-usd',
      },
    );
  },
  updateNotifications(notification) {
    EventBus.$emit('update-notifications');
  },
  setOnlineUsers(users) {
    app.store.commit('users/SET_ONLINE_USERS', users);
  },

  socketconnection(msg) {
    console.info(msg);
  },
  checkStripe(value) {
    EventBus.$emit('update-stripe');
  },
  appHashHandler(payload) {
    EventBus.$emit('app-hash', payload);
  },
  verifyIdentity(payload) {
    app.$toast.show(
      app.i18n.t(
        `sidebar.section.verify_identity.${payload.toLowerCase()}.toast`,
      ),
      {
        icon: 'alert-circle',
      },
    );
  },
  /*
   * CONNECTIONS AND MESSAGES CHANNEL HANDLERS
   */
  onConnectionsUpdated(connectionInfo) {
    if (!app.$api.commons.connectionsEnabled()) return;
    const userId = connectionInfo.connectionid
      .split('-')
      .find((id) => id !== app.$auth.user.data.id);
    connectionInfo.id = userId;
    if (connectionInfo.requestbsvexchange && connectionInfo.created_at) {
      app.store
        .dispatch('connections/addConnection', {
          userId,
          connectionInfo,
        })
        .then(() => UserBus.$emit('update-connection', userId));
    } else {
      app.store
        .dispatch('connections/updateConnection', {
          userId,
          connectionInfo,
        })
        .then(() => UserBus.$emit('update-connection', userId));
    }

    if (connectionInfo.status)
      app.store
        .dispatch('users/updateUser', {
          userid: userId,
          connection_status: connectionInfo.status,
        })
        .then(() => {
          UserBus.$emit('update-user', { id: userId });
          EventBus.$emit('update-user-info', {
            userid: userId,
            connection_status: connectionInfo.status,
          });
        });
  },
  async OnReceiveMsg(msg) {
    if (!app.$api.commons.messagesEnabled()) return;
    const messageIdSplited = msg.messageid.split('-');
    const { id } = app.$auth.user.data;
    const sourceId = messageIdSplited[1];
    const destinationId = messageIdSplited[2];
    const conversationId = id === sourceId ? destinationId : sourceId;
    const messageToUpdate = app.store.getters['messages/getChatWithUser'](
      conversationId,
    ).filter((message) => message.id === msg.messageid);
    console.log('HAS MESSAGE ON CONVERSATION', Boolean(messageToUpdate.length));
    if (messageToUpdate.length) {
      const messageWithNewStatus = { ...messageToUpdate[0] };
      messageWithNewStatus.status = msg.status;
      return app.store.dispatch('messages/updateMessageFromChat', {
        loggedUserId: id,
        msg: messageWithNewStatus,
        conversationId,
      });
    } else {
      if (msg.sourcenick && msg.message) {
        const decryptedMsg = await decryptMessage({
          prefix: msg.created_at,
          from: msg.sourcenick,
          to: msg.destinationnick,
          message: msg.message,
        });
        msg.message = decryptedMsg;
        msg.stream =
          msg.destinationid === id ? MESSAGES_STREAM_IN : MESSAGES_STREAM_OUT;
        if (msg.messageid) {
          msg.id = msg.messageid;
        }
        app.store.commit('connections/ADD_NEW_MSG', msg);
        app.store.dispatch('messages/addMsgsToChat', {
          conversationId,
          msgs: [msg],
          loggedUserId: id,
          unshift: false,
        });
      } else {
        // If message are sent and read at the same time
        const checkIfMsgAreSetted = setInterval(() => {
          const messageToUpdate = app.store.getters['messages/getChatWithUser'](
            conversationId,
          ).filter((message) => message.id === msg.messageid);
          messageToUpdate.status = msg.status;
          if (messageToUpdate.length) {
            app.store.dispatch('messages/updateMessageFromChat', {
              loggedUserId: id,
              msg: messageToUpdate,
              conversationId,
            });
            clearInterval(checkIfMsgAreSetted);
          }
        }, 500);
      }
    }
  },
  /*
   * MULTICAST CHANNEL HANDLERS
   */
  profileupdated(data) {
    if (app.$auth.loggedIn && data.userid === app.$auth.user.data.id) {
      delete data.userid;
      app.store.dispatch('updateUserInfo', data).then(() =>
        UserBus.$emit('update-user', {
          id: app.$auth.user.data.id,
          nick: app.$auth.user.data.nick,
        }),
      );
    } else {
      EventBus.$emit('update-user-info', data);
      app.store.dispatch('users/updateUser', data);
    }
  },
  updateOnlineUsers(users) {
    app.store.commit('users/SET_ONLINE_USERS', users);
  },
  async updateOfferings(data) {
    console.log(data);
    if (app.$api.commons.onlySellers()) {
      let oldSellers = app.store.getters['users/getAllSellers'];
      const newSellers = data.sellers.filter(
        (id) => !oldSellers.some((seller) => seller.id === id),
      );
      if (newSellers.length > 0) {
        app.store.commit(`users/${SET_REQUESTED_ALL_SELLERS}`, false);
        if (newSellers.length > 5 || oldSellers.length === 0) {
          await app.$api.user.getAllSellers();
        } else {
          for (const id of newSellers) {
            await app.$api.user.getUserById(id);
          }
          app.store.commit(`users/${SET_REQUESTED_ALL_SELLERS}`, true);
        }
      }
    }
    if (data.openOfferings.length > 0)
      EventBus.$emit('update-offerings', data.openOfferings);
    if (Object.keys(data.changedStatusOfferings).length > 0)
      for (const id in data.changedStatusOfferings) {
        const offeringInProcess =
          app.store.getters['market/offeringsInProcess'][id];
        if (offeringInProcess)
          app.store.dispatch(`market/${setOfferingProcess}`, {
            offeringId: id,
            status: null,
          });
        EventBus.$emit('update-offering', { id });
      }
  },
  updateMarketItem(data) {
    const id = data.offering_id ? data.offering_id : data.id ? data.id : null;
    if (id !== null) {
      if (id.includes('oF')) EventBus.$emit('update-offering', { id });
      else {
        if (
          !app.router.currentRoute.path.includes(id) &&
          !!app.router.currentRoute.path.includes('mint-form')
        )
          app.router.push(
            app.localePath({
              name: 'market-product-id',
              params: { id },
              query: { type: 'available' },
            }),
          );
        else EventBus.$emit('update-product', { id });
      }
    }
  },
  notifyMarketPurchase(data) {
    if (data.customer_id && data.customer_id === app.$auth.user.data.id)
      app.$toast.info(
        app.i18n.t(
          `market.checkout_session.notification.${
            data.error === true ? 'error' : 'success'
          }`,
          { item: data.tokenName },
        ),
      );
  },
  updateBids(data) {
    EventBus.$emit('update-bids', { id: data.offering_id });
  },
  createOffering(data) {
    EventBus.$emit('creating-offering', data);
  },
  async profilenew(data) {
    try {
      app.store.commit('users/ADD_USER_REQUEST_USER', data.userid);
      const user = await app.$api.users.getOneUserById(data.userid);
      app.store.dispatch('users/addUser', user);
    } catch (error) {
      console.log(error);
    }
  },
});
