// appNotifications.js
export const RESET_STATE_APPNOTIFICATIONS = 'RESET_STATE';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_MESSAGE_NOTIFICATIONS = 'SET_MESSAGE_NOTIFICATIONS';
export const SET_MESSAGE_NOTIFICATIONS_COUNTER =
  'SET_MESSAGE_NOTIFICATIONS_COUNTER';
export const SET_NOTIFICATIONS_COUNTER = 'SET_NOTIFICATIONS_COUNTER';
export const SET_UNREAD_MSGS_WITH_USER = 'SET_UNREAD_MSGS_WITH_USER';

// connections.js
export const RESET_STATE_CONNECTIONS = 'RESET_STATE';
export const ADD_NEW_MSG = 'ADD_NEW_MSG';
export const ADD_UPDATE_MSG = 'ADD_UPDATE_MSG';
export const CLEAN_NEW_MSGS = 'CLEAN_NEW_MSGS';
export const CLEAN_MSGS_TO_UPDATE = 'CLEAN_MSGS_TO_UPDATE';
export const TOGGLE_LOADING_CONNECTIONS = 'TOGGLE_LOADING';
export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const SET_LOADING_CONNECTION = 'SET_LOADING_CONNECTION';

// devices.js
export const SET_AUDIO_INPUT = 'SET_AUDIO_INPUT';
export const SET_VIDEO_INPUT = 'SET_VIDEO_INPUT';

// index.js
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SET_LOGGING_OUT = 'SET_LOGGING_OUT';

// mailing.js
export const SET_INFO_MAILING_MODAL = 'SET_INFO_MAILING_MODAL';
export const TOGGLE_SHOW_MAILING_MODAL = 'TOGGLE_SHOW_MAILING_MODAL';
export const SET_EMAIL_LIST_MAILING_MODAL = 'SET_EMAIL_LIST_MAILING_MODAL';
export const SET_MESSAGE_MAILING_MODAL = 'SET_MESSAGE_MAILING_MODAL';

// market.js
export const TOGGLE_SHOW_LISTING_PRODUCT_MODAL =
  'TOGGLE_SHOW_LISTING_PRODUCT_MODAL';
export const SET_SELECTED_FILE_MODAL = 'SET_SELECTED_FILE_MODAL';
export const TOGGLE_SHOW_HANDLE_INVITATION_OFFERING_MODAL =
  'TOGGLE_SHOW_HANDLE_INVITATION_OFFERING_MODAL';
export const TOGGLE_SHOW_FILE_PREVIEW_MODAL = 'TOGGLE_SHOW_FILE_PREVIEW_MODAL';
export const SET_ERROR_FILE_MODAL = 'SET_ERROR_FILE_MODAL';
export const SET_SELECTED_G2C_OBJECT_MODAL = 'SET_SELECTED_G2C_OBJECT_MODAL';
export const TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL =
  'TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL';
export const SET_LOADING_G2C_OBJECT_MODAL = 'SET_LOADING_G2C_OBJECT_MODAL';
export const TOGGLE_SHOW_TRANSFER_PRODUCT_MODAL =
  'TOGGLE_SHOW_TRANSFER_PRODUCT_MODAL';
export const TOGGLE_SHOW_DELIST_OFFERING_MODAL =
  'TOGGLE_SHOW_DELIST_OFFERING_MODAL';
export const TOGGLE_SHOW_REDEEM_PRODUCT_MODAL =
  'TOGGLE_SHOW_REDEEM_PRODUCT_MODAL';
export const TOGGLE_SHOW_CREATE_QR_MODAL = 'TOGGLE_SHOW_CREATE_QR_MODAL';
export const TOGGLE_SHOW_TICKET_OPTIONS_MODAL =
  'TOGGLE_SHOW_TICKET_OPTIONS_MODAL';
export const SET_SELECTED_PRODUCT_FOR_MODAL = 'SET_SELECTED_PRODUCT_FOR_MODAL';
export const TOGGLE_SHOW_HANDLE_COLLECTIONS_MODAL =
  'TOGGLE_SHOW_HANDLE_COLLECTIONS_MODAL';
export const TOGGLE_SHOW_HANDLE_ADDRESS_MODAL =
  'TOGGLE_SHOW_HANDLE_ADDRESS_MODAL';
export const SET_SELECTED_ADDRESS_MODAL = 'SET_SELECTED_ADDRESS_MODAL';
export const TOGGLE_SHOW_HANDLE_OWNER_COLLECTIONS_MODAL =
  'TOGGLE_SHOW_HANDLE_OWNER_COLLECTIONS_MODAL';
export const SET_OWN_OWNER_COLLECTIONS = 'SET_OWN_OWNER_COLLECTIONS';
export const SET_LOADING_COLLECTIONS_MODAL = 'SET_LOADING_COLLECTIONS_MODAL';
export const SET_SELECTED_COLLECTION_MODAL = 'SET_SELECTED_COLLECTION_MODAL';
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const TOGGLE_SHOW_MAKE_OFFER_MODAL = 'TOGGLE_SHOW_MAKE_OFFER_MODAL';
export const TOGGLE_SHOW_CHECKOUT_SESSION = 'TOGGLE_SHOW_CHECKOUT_SESSION';
export const TOGGLE_SHOW_AUCTION_PAY_MODAL = 'TOGGLE_SHOW_AUCTION_PAY_MODAL';
export const TOGGLE_SHOW_HANDLE_ROYALTY_MODAL =
  'TOGGLE_SHOW_HANDLE_ROYALTY_MODAL';
export const SET_SELECTED_ROYALTY_MODAL = 'SET_SELECTED_ROYALTY_MODAL';
export const TOGGLE_SHOW_HANDLE_BENEFICIARY_MODAL =
  'TOGGLE_SHOW_HANDLE_BENEFICIARY_MODAL';
export const SET_SELECTED_BENEFICIARY_MODAL = 'SET_SELECTED_BENEFICIARY_MODAL';
export const SET_SELECTED_REDEEMER_MODAL = 'SET_SELECTED_REDEEMER_MODAL';
export const SET_OFFERING_PROCESS = 'SET_OFFERING_PROCESS';
export const SET_LISTING_STATUS = 'SET_LISTING_STATUS';
export const SET_TRANSFER_STATUS = 'SET_TRANSFER_STATUS';
export const SET_REDEEM_STATUS = 'SET_REDEEM_STATUS';
export const SET_SELECTED_ACCESS_OPTION = 'SET_SELECTED_ACCESS_OPTION';
export const SET_SELECTED_ACCESS_INVITATIONS =
  'SET_SELECTED_ACCESS_INVITATIONS';
export const SET_OFFERING_TO_LIST = 'SET_OFFERING_TO_LIST';
export const SHOW_CONFIRM_LISTING_MODAL = 'SHOW_CONFIRM_LISTING_MODAL';
export const ERROR_CREATING_MARKET_OBJECT = 'ERROR_CREATING_MARKET_OBJECT';

// messages.js
export const SET_LOADING = 'SET_LOADING';
export const SET_CONNECTION_MESSAGE = 'SET_CONNECTION_MESSAGE';
export const SET_CONNECTION_MESSAGES = 'SET_CONNECTION_MESSAGES';
export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE';
export const SET_CHATS = 'SET_CHATS';
export const TOGGLE_LOADING_CHATS = 'TOGGLE_LOADING_CHATS';
export const TOGGLE_LOADED_CHATS = 'TOGGLE_LOADED_CHATS';
export const ADD_CONN_MSG_TO_CHATS = 'ADD_CONN_MSG_TO_CHATS';
export const ADD_MSGS_TO_CHAT = 'ADD_MSGS_TO_CHAT';
export const UPDATE_MSGS_ON_CHAT = 'UPDATE_MSGS_ON_CHAT';
export const UPDATE_MSG_TO_CHAT = 'UPDATE_MSG_TO_CHAT';
export const SET_LOADING_CONNECTION_MESSAGES =
  'SET_LOADING_CONNECTION_MESSAGES';
export const ADD_MESSAGE_PENDING_TO_UNBLOCK = 'ADD_MESSAGE_PENDING_TO_UNBLOCK';

// notifications.js
export const RESET_STATE_NOTIFICATIONS = 'RESET_STATE';
export const INIT_NOTIFICATION_OBJECT_WITH_USER =
  'INIT_NOTIFICATION_OBJECT_WITH_USER';
export const INIT_NOTIFICATION_OBJECT_WITH_ROOM =
  'INIT_NOTIFICATION_OBJECT_WITH_ROOM';
export const ADD_NOTIFICATION_WITH_USER = 'ADD_NOTIFICATION_WITH_USER';
export const ADD_NOTIFICATION_WITH_ROOM = 'ADD_NOTIFICATION_WITH_ROOM';
export const REMOVE_NOTIFICATIONS_TYPE_WITH_USER =
  'REMOVE_NOTIFICATIONS_TYPE_WITH_USER';
export const REMOVE_NOTIFICATIONS_WITH_ROOM_BY_ID =
  'REMOVE_NOTIFICATIONS_WITH_ROOM_BY_ID';

// report.js
export const SET_INFO_REPORT_MODAL = 'SET_INFO_REPORT_MODAL';
export const TOGGLE_SHOW_REPORT_MODAL = 'TOGGLE_SHOW_REPORT_MODAL';

// processing.js
export const SET_G2C_IN_PROCESS = 'SET_G2C_IN_PROCESS';
export const SET_MARKET_OBJECT = 'SET_MARKET_OBJECT';
export const REMOVE_MARKET_OBJECT = 'REMOVE_MARKET_OBJECT';
export const SET_TOKEN_MINTIN = 'SET_TOKEN_MINTIN';
export const SHOW_G2C_IN_PROCESS_MODAL = 'SHOW_G2C_IN_PROCESS_MODAL';
export const REMOVE_G2C_ALERT = 'REMOVE_G2C_ALERT';
export const CREATE_G2C_ALERT = 'CREATE_G2C_ALERT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// publicProfile.js
export const SET_PUBLIC_PROFILE_USER = 'SET_PUBLIC_PROFILE_USER';
export const SET_PUBLIC_PROFILE_THEME = 'SET_PUBLIC_PROFILE_THEME';
export const SET_PUBLIC_PROFILE_FEATURES = 'SET_PUBLIC_PROFILE_FEATURES';
export const SET_PUBLIC_PROFILE_GRID = 'SET_PUBLIC_PROFILE_GRID';
export const SET_PUBLIC_PROFILE_PREVIEW_STYLE =
  'SET_PUBLIC_PROFILE_PREVIEW_STYLE';
export const SET_PUBLIC_PROFILE_PREVIEW_PROGRESS =
  'SET_PUBLIC_PROFILE_PREVIEW_PROGRESS';
export const INCREASE_PUBLIC_PROFILE_PREVIEW_PROGRESS =
  'INCREASE_PUBLIC_PROFILE_PREVIEW_PROGRESS';
export const SET_PUBLIC_PROFILE_SEO = 'SET_PUBLIC_PROFILE_SEO';
export const SET_PUBLIC_PROFILE_IS_OWNER = 'SET_PUBLIC_PROFILE_IS_OWNER';
export const SET_PUBLIC_PROFILE_HEADER_IMAGE =
  'SET_PUBLIC_PROFILE_HEADER_IMAGE';
export const SET_PUBLIC_PROFILE_THEMES = 'SET_PUBLIC_PROFILE_THEMES';
export const SET_PUBLIC_PROFILE_STATUS = 'SET_PUBLIC_PROFILE_STATUS';
export const SET_PUBLIC_PROFILE_VIDEO_LINK = 'SET_PUBLIC_PROFILE_VIDEO_LINK';

// rooms.js
export const TOGGLE_SHOW_PREVIEW_MODAL = 'TOGGLE_SHOW_PREVIEW_MODAL';
export const SET_SELECTED_PREVIEW_MODAL = 'SET_SELECTED_PREVIEW_MODAL';
export const TOGGLE_SHOW_ROOM_MODAL = 'TOGGLE_SHOW_ROOM_MODAL';
export const SET_SELECTED_ROOM_MODAL = 'SET_SELECTED_ROOM_MODAL';
export const RESET_STATE_ROOMS = 'RESET_STATE';
export const TOGGLE_LOADING_ROOMS = 'TOGGLE_LOADING';
export const SET_ROOMS = 'SET_ROOMS';
export const ADD_ROOM = 'ADD_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const UPDATE_ROOM_STATUS = 'UPDATE_ROOM_STATUS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const REMOVE_ROOM = 'REMOVE_ROOM';
export const SET_IDS = 'SET_IDS';
export const ADD_TO_FAV = 'ADD_TO_FAV';
export const REMOVE_TO_FAV = 'REMOVE_TO_FAV';
export const ADD_NOW_ROOM = 'ADD_NOW_ROOM';
export const REMOVE_NOW_ROOM = 'REMOVE_NOW_ROOM';
export const SET_WAITING_ROOM = 'SET_WAITING_ROOM';

// session.js
export const SET_SELECTED_ACTION_MODAL = 'SET_SELECTED_ACTION_MODAL';
export const TOGGLE_SHOW_SESSION_MODAL = 'TOGGLE_SHOW_SESSION_MODAL';
export const TOGGLE_SHOW_VERIFY_ENTITY_MODAL =
  'TOGGLE_SHOW_VERIFY_ENTITY_MODAL';

// tokens.js
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_LISTED_PRODUCT = 'ADD_LISTED_PRODUCT';
export const SET_LOADING_PRODUCTS = 'SET_LOADING_PRODUCTS';
export const ADD_TOKEN_OBJECT = 'ADD_TOKEN_OBJECT';
export const SET_OFFERING_BIDS = 'SET_OFFERING_BIDS';
export const SET_MINIMUM_BID = 'SET_MINIMUM_BID';
export const SET_HIGHEST_BID = 'SET_HIGHEST_BID';
export const SET_LOADING_BIDS = 'SET_LOADING_BIDS';
export const RESET_STATE_TOKENS = 'RESET_STATE';
export const RESET_STATE_BIDS = 'RESET_STATE';

// tokenResusableModule.js
export const SET_TOKEN_SERIE_ID = 'SET_TOKEN_SERIE_ID';
export const SET_TOKEN_SERIE_CONTRACT = 'SET_TOKEN_SERIE_CONTRACT';
export const SET_TOKEN_SERIE_LIST = 'SET_TOKEN_SERIE_LIST';
export const SET_UNIQUE_TOKEN = 'SET_UNIQUE_TOKEN';

// users.js
export const RESET_STATE_USER = 'RESET_STATE';
export const TOGGLE_REQUESTED_ALL_USERS = 'TOGGLE_REQUESTED_ALL_USERS';
export const TOGGLE_REQUESTED_ALL_COMPANIES = 'TOGGLE_REQUESTED_ALL_COMPANIES';
export const TOGGLE_ALL_USERS_ARE_LOADED = 'TOGGLE_ALL_USERS_ARE_LOADED';
export const TOGGLE_ALL_COMPANIES_ARE_LOADED =
  'TOGGLE_ALL_COMPANIES_ARE_LOADED';
export const ADD_USER_REQUEST_USER = 'ADD_USER_REQUEST_USER';
export const ADD_USER_NICK_REQUEST_USER = 'ADD_USER_NICK_REQUEST_USER';
export const REMOVE_USER_FROM_REQUESTED_USERS =
  'REMOVE_USER_FROM_REQUESTED_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USERS = 'ADD_USERS';
export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const TOGGLE_SHOW_USER_CONNECT_MODAL = 'TOGGLE_SHOW_USER_CONNECT_MODAL';
export const SET_SELECTED_USER_CONNECT_MODAL =
  'SET_SELECTED_USER_CONNECT_MODAL';
export const TOGGLE_SHOW_USER_MODAL = 'TOGGLE_SHOW_USER_MODAL';
export const SET_SELECTED_USER_MODAL = 'SET_SELECTED_USER_MODAL';
export const SET_OPTIONS_USER_MODAL = 'SET_OPTIONS_USER_MODAL';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_SELLERS = 'SET_SELLERS';
export const SET_REQUESTED_ALL_SELLERS = 'SET_REQUESTED_ALL_SELLERS';
