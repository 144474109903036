import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  header: {
    title: 'Earn more from what you do.',
    subtitle: 'Where your physical and digital products thrive.',
    cta: 'Start selling',
  },
  section_cta2: {
    cta_1: 'Earn royalties forever.',
    cta_2: 'Be the first to experience the future.',
    cta_2_btn: `Join ${MARKETPLACE_NAME} Marketplace 👑`,
    cta_3: {
      base: 'Mint, sell, and watch your earnings ',
      t_4: 'g',
      t_3: 'r',
      t_2: 'o',
      t_1: 'w',
    },
    cta_4: 'Earn more from what you do',
  },
  benefits_section: {
    title: {
      first: 'Embrace the Minters',
      second: 'Direct Connections',
      third: 'Keep More Profits',
    },
    body: {
      first: 'We champion and support creators like you',
      second: 'Say goodbye to unnecessary intermediaries',
      third: 'Say hello to lower fees and bigger rewards',
    },
  },
  resale_section: {
    title: 'Resale Royalties',
    body: `Sell your products on ${MARKETPLACE_NAME} Marketplace adding a royalty and earn ongoing revenue when they're resold.`,
    button: 'Try it now',
  },
  no_fees_section: {
    banner: '0% fees until 30th september',
    title: 'Game-Changing Sales Fee',
    body: 'With just a 2% fee per sale, unleash your sales potential and skyrocket your volumes.',
    button: 'Try it now',
  },
  auction_section: {
    title: 'Real-Time Auction Excitement',
    body: 'Let your audience bid in auctions and uncover the true value of your offerings.',
    button: 'Try it now',
  },
  traceability_section: {
    title: 'Know what happens to your products once sold',
    body: 'Gain visibility and set rules for the future use of your sold products.',
    button: 'Try it now',
  },
  currencies_section: {
    title: 'We welcome different currencies',
    body: 'Boost your sales potential by accepting payments in multiple currencies, reaching a global audience.',
    button: 'Try it now',
  },
  blockchain_section: {
    title: 'Advantages of Blockchain Technology',
    body: 'Experience unparalleled transparency and secure distribution methods.',
    button: 'Try it now',
  },
  steps_section: {
    title: 'Get started in 5 steps:',
    stepper: {
      first: {
        title: 'Sign up',
      },
      second: {
        title: 'Verify your account',
      },
      third: {
        title: 'Mint a Token',
        body: 'Meaning: create your product, service or ticket',
      },
      fourth: {
        title: 'Publish on the Market',
      },
      fifth: {
        title: 'Sell, and sell more 💸',
      },
    },
    button: 'Start selling',
  },
  categories_section: {
    title: 'Mint anything you like',
  },
  offerings_section: {
    title: 'A world of possibilities at your fingertips',
    body: 'Explore our user-friendly marketplace filled with endless functionalities.',
    button: 'Unlock your earning potential 🗝️',
  },
  faq_section: {
    title: 'FAQ’s',
    button: {
      more: 'Show more questions',
      less: 'Show less questions',
    },
    list: {
      1: {
        question: 'What is a token?',
        answer: `A token is a digital representation of something unique, such as a digital product, ticket, or service. These tokens are created and exchanged on a blockchain network, which provides a secure and decentralized database. They hold value and can be bought, sold, or traded on token exchanges, just like ${MARKETPLACE_NAME} Marketplace.`,
      },
      2: {
        question: 'Who is a Minter and how can you become one?',
        answer: `<p>A Minter on the ${MARKETPLACE_NAME} Marketplace platform is an individual who creates a digital representation of a product, ticket, or service within the platform. To become a Minter, simply create an account on ${MARKETPLACE_NAME} Marketplace, complete the verification process, and click on "Mint a Token." </p>
        <p>Follow the straightforward steps, and voilà! Your creation is now transformed into a digital asset. Moreover, you have the flexibility to add rules to the asset, such as specifying whether it can be purchased, resold, or transferred.</p>
        <p>Stay informed about the activities surrounding your minted assets by conveniently monitoring them on the "My Mints" and "My Tokens" pages.</p>`,
      },
      3: {
        question: 'How to mint a token?',
        answer: `<ol><li>1. Create an account and verify it.
        <p class="ml-2">Register and complete the verification process.</p></li>
        <li>2. Click the "Mint a Token" button.
        <p class="ml-2">Located at the top right corner of the page.</p></li>
        <li>3. Follow the instructions for token creation.
        <p class="ml-2">- Select the type of token (physical product, service, digital product, ticket).</p>
        <p class="ml-2">- Add an images, description, and any additional fields.</p></li>
        <li>4. Set up pricing and selling options.
        <p class="ml-2">- Choose the "set price" or "auction" format for selling.</p>
        <p class="ml-2">- CSpecify additional rules, such as allowing resale or transfer of the token.</p>
        <p class="ml-2">- Define the percentage you would receive with each resale.</p></li>
        <li>5. Preview and verify your token.
        <p class="ml-2">- Review all the details and ensure accuracy.</p></li>
        <li>6. Click "Mint a Token" to generate the digital representation.
        <p class="ml-2">- Once clicked, the token is created and recorded on the blockchain.</p></li></ol>
        <p>Remember that tokens, once minted, cannot be modified. It's essential to ensure all information is correct before finalizing the minting process.</p>`,
      },
      4: {
        question: `Why choose ${MARKETPLACE_NAME} Marketplace?`,
        answer: `<p>Let us give you plenty of reasons why!</p>
        <ul><li>Security and Reliability:
        <p>At ${MARKETPLACE_NAME} Marketplace, we prioritize the safety and security of our platform. You can trust that your creations are protected when minting and selling through us.</p>
        <p>Our commitment to reliability ensures a smooth experience for both creators and buyers.</p>
        <p>With our global reach, you can access a wide customer base and sell your products to buyers all around the world.</p></li>
        <li>Earn Resale Royalties:
        <p>We believe in fair compensation for creators. With ${MARKETPLACE_NAME} Marketplace, you can earn royalties each time your products, tickets, or services are resold.</p>
        <p>As the value of your creations grows, you continue to earn money from their resale, providing ongoing revenue for your work.</p></li>
        <li>Auctions to discover the true value of your creations:
        <p>Auctions provide an exciting opportunity to determine the true value of your creations. With ${MARKETPLACE_NAME} Marketplace, you can auction off your items to interested buyers and witness the price they are willing to pay. This feature allows you to gauge the market demand for your work and potentially unlock higher value for your creations.</p>
        <li>Community and Connections:
        <p>${MARKETPLACE_NAME} Marketplace offers more than just a platform for minting and selling creations. It's a space for connecting with fellow creators, building a community, and engaging with your audience.</p>
        <p>Showcase your talent, connect with other creators, and explore exclusive creations from fellow ${MARKETPLACE_NAME} Marketplace users.</p>
        <p>This vibrant community allows you to expand your horizons, gain inspiration, and foster collaborations.</p>
        <p style="text-indent:0px">By choosing ${MARKETPLACE_NAME} Marketplace, you gain access to a secure platform, opportunities for ongoing earnings, the excitement of auctions, and a vibrant creative community. Join us and unlock the full potential of your creations!</p></li><ul>`,
      },
      5: {
        question: 'What does "List to Market" mean?',
        answer: `<p>Once you have minted a token, your product, service, or ticket is initially visible only to you. To make it available to potential buyers, you need to click on the "List to Market" option located on the right side of the platform. This action allows you to choose between two selling options: fixed price or auction.</p>
        <p>By selecting the "List to Market" option, you open up your token to potential buyers, providing them with the opportunity to acquire your product, service, or ticket either at a fixed price or through a dynamic auction process.</p>`,
      },
      6: {
        question:
          'How do I transfer a product, a ticket, or a service to another user?',
        answer: `<p>First ensure that the recipient has an active account on the platform. Then, select the item you wish to transfer, click the "Transfer" button, and specify the recipient's username.</p>`,
      },
      7: {
        question: `What types of products can I issue on ${MARKETPLACE_NAME} Marketplace?`,
        answer: `<p>${MARKETPLACE_NAME} Marketplace provides a diverse range of options for creators to issue and sell their digital products, services, or tickets. The platform accommodates a wide array of offerings, such as digital art, music, videos, e-books, software, tickets, memberships, VIP passes, and beyond. The possibilities are endless when it comes to what you can create and offer for sale on ${MARKETPLACE_NAME} Marketplace! With its evidence track feature, ${MARKETPLACE_NAME} Marketplace guarantees authenticity and ownership, empowering creators to showcase their unique creations while maintaining a trusted environment for buyers.</p>`,
      },
      8: {
        question: `How do auctions work on ${MARKETPLACE_NAME} Marketplace?`,
        answer: `<p>In addition to selling products and services, creators on ${MARKETPLACE_NAME} Marketplace can harness the power of auctions to enhance their earnings and discover the true value of their creations. By listing a token for auction, creators can determine the duration of the bidding process and observe how much buyers are willing to pay for their offerings. This provides valuable insights into the demand for their products or services, potentially resulting in higher earnings compared to traditional sales channels.</p>
        <p>As a minter, you have control over the duration of the auction and the ability to stop the process at any time. Interested buyers can place their bids, indicating their willingness to pay for your offering. Ultimately, the highest bidder at the conclusion of the auction becomes the purchaser of the item.</p>
        <p>Creators can conveniently monitor the progress of their auctions through their profiles, allowing them to stay informed and engaged throughout the bidding process.</p>`,
      },
      9: {
        question: 'What are royalties and how can I earn from resales?',
        answer: `<p>When you create a token on ${MARKETPLACE_NAME} Marketplace, you have the flexibility to define the terms and conditions for reselling your product, ticket, or service. You can choose whether resales are allowed, and if so, set a percentage of royalties that you will earn from each subsequent resale. This provides you with an ongoing opportunity to benefit from the continued success of your offering, even after the initial sale. You have full control over the percentage of royalties you receive, enabling you to tailor your pricing strategy to maximize your earnings.</p>`,
      },
      10: {
        question: `Can my product or service sold on ${MARKETPLACE_NAME} Marketplace be resold on other platforms?`,
        answer: `<p>To ensure transaction security and maintain the integrity of products created on the ${MARKETPLACE_NAME} Marketplace platform, all resales and transfers of products, services, or tickets can only take place within our community. This ensures a safe and trusted environment for buyers and sellers within the ${MARKETPLACE_NAME} Marketplace ecosystem.</p>`,
      },
      11: {
        question: `How are payments processed on ${MARKETPLACE_NAME} Marketplace?`,
        answer: `<p>As a Minter on ${MARKETPLACE_NAME} Marketplace, you can receive payments for your sales through our partner payment platform, Stripe. ${MARKETPLACE_NAME} Marketplace exclusively operates with fiat, allowing you to receive payments via credit cards. This ensures a convenient and familiar payment experience for both buyers and sellers. Once you receive the payments, you can easily withdraw your earnings to your bank account and utilize them for your personal or business needs.</p>`,
      },
      12: {
        question: `What are the fees for selling on ${MARKETPLACE_NAME} Marketplace?`,
        answer: `<p>${MARKETPLACE_NAME} Marketplace distinguishes itself from other platforms by only charging a 2% fee on each sale. This competitive fee structure allows creators and companies to maximize their earnings from digital products and services, surpassing platforms that charges higher fees. By reducing fees, ${MARKETPLACE_NAME} Marketplace enables creators and companies to retain a larger portion of their revenue, providing opportunities to expand their business, invest in new offerings, or boost overall profitability.</p>`,
      },
    },
  },
  angel_section: {
    title: 'Need help?',
    body: `Contact a ${MARKETPLACE_NAME} Marketplace Angel and we’ll help you set up your tokens and sales.`,
    button: `Contact a ${MARKETPLACE_NAME} Marketplace Angel`,
  },
  newsletter_section: {
    title: 'Keep me posted with news',
    input: {
      placeholder: 'name@email.com',
    },
    hint: 'We won’t spam, promise.',
    button: 'Subscribe',
    success: 'We will send you news!',
    valid: 'Please enter a valid email',
    errors: {
      generic: 'Impossible to subscribe',
    },
  },
  footer: {
    contact: 'Contact us',
    sections: {
      discover: {
        title: 'Discover',
        subsections: {
          market: {
            label: 'Market',
            path: 'market-preview-marketplace',
          },
          community: {
            label: 'Community',
            path: 'community',
          },
          messages: {
            label: 'Messages',
            path: 'messages',
          },
        },
      },
      legal: {
        title: 'Legal',
        subsections: {
          terms_conditions: {
            label: 'Terms & conditions',
            path: 'legal-terms',
          },
        },
      },
      company: {
        title: 'Company',
        subsections: {
          gate2chain: {
            label: 'Gate2Chain',
            url: 'https://www.gate2chain.com',
          },
          investors: {
            label: 'Investors',
            url: 'https://www.gate2chain.com',
          },
        },
      },
      follow: {
        title: 'Follow',
        subsections: {
          twitter: {
            label: 'Twitter',
            url: 'https://twitter.com',
          },
          linkedin: {
            label: 'LinkedIn',
            url: 'https://www.linkedin.com',
          },
          youtube: {
            label: 'YouTube',
            url: 'https://www.youtube.com/',
          },
        },
      },
    },
  },
  disclaimer: {
    g2c: 'G2C Suite',
    marketplace: `${MARKETPLACE_NAME}Marketplace©2023 is built with`,
    terms: 'Terms & Conditions',
    contact: 'Contact us',
  },
};
