import { render, staticRenderFns } from "./LayoutNotificationsBtn.vue?vue&type=template&id=13da1d42&scoped=true&"
import script from "./LayoutNotificationsBtn.vue?vue&type=script&lang=js&"
export * from "./LayoutNotificationsBtn.vue?vue&type=script&lang=js&"
import style0 from "./LayoutNotificationsBtn.vue?vue&type=style&index=0&id=13da1d42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13da1d42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/tmp/build_51aa5b7f/components/base/BaseIcon.vue').default,BaseNotificationBadge: require('/tmp/build_51aa5b7f/components/base/BaseNotificationBadge.vue').default,SidebarNotificationListItem: require('/tmp/build_51aa5b7f/components/sidebar-notification/SidebarNotificationListItem.vue').default})
