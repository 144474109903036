import { MARKETPLACE_COMMISSION, MARKETPLACE_NAME } from '~/vars/api';

export default {
  built: 'Built with',
  minta_market: 'Minta Marketplaces',
  marketplace_name: `${MARKETPLACE_NAME}`,
  platform: `${MARKETPLACE_NAME} Marketplace`,
  about: 'About',
  try_again: 'Try again',
  send_message: 'Send a message',
  send: 'Send',
  accept: 'Accept',
  continue: 'Continue',
  confirm: 'Confirm',
  finish: 'Finish',
  accepted: 'Accepted',
  cancel: 'Cancel',
  back: 'Back',
  close: 'Close',
  clear: 'Clear',
  clear_all: 'Clear all',
  select_all: 'Select all',
  see_more: 'See more',
  see_less: 'See less',
  show_more: 'Show more',
  show_less: 'Show less',
  see_n_more: '{n} more',
  see_maps: 'Open in google maps',
  filters: 'Filters',
  pending: 'Pending',
  retry: 'Retry',
  dismiss: 'Dismiss',
  decline: 'Decline',
  disconnect: 'Disconnect',
  date: 'Date',
  hour: 'Hour',
  ending: 'Ending',
  title: 'Title',
  duration: 'Duration',
  estimated_duration: 'Estimated duration',
  company_fees: `${MARKETPLACE_NAME} Marketplace Fees ${MARKETPLACE_COMMISSION} %`,
  minus_fees: '(Minus fees)',
  no: 'No',
  yes: 'Yes',
  of: 'of',
  or: 'or',
  next: 'Next',
  loading: 'Loading...',
  uploading: 'Uploading changes...',
  updating: 'Updating changes...',
  done: 'Done',
  seetx: 'See Tx',
  see_activity: 'See Activity',
  activity_details: 'Activity details',
  back_to: 'Back to {section}',
  contribute_here: 'contribute here',
  handle_paymail: 'Handle & Paymail',
  knowledges: 'Knowledges',
  entity_data: '{userOption} Data',
  entity_account: '{userOption} Account',
  summary: 'Summary',
  languages: 'Languages',
  social_media: 'Social media',
  message: 'Message',
  apply: 'Apply',
  error: 'Error loading',
  edit: 'Edit',
  save: 'Save',
  save_next: 'Save and next',
  soon: 'Coming soon',
  hidden: '***',
  amount: 'Amount',
  status: 'Status',
  total_earned: 'Total earned',
  total_expended: 'Total expended',
  earned: 'Earned',
  expended: 'Expended',
  you: 'You',
  has: 'has',
  have: 'have',
  has_not: "hasn't",
  have_not: "haven't",
  none: 'None',
  optional: 'Optional',
  sent_to: 'sent to',
  to: 'to',
  from: 'from',
  booking: 'booking',
  payment: 'payment',
  connection: 'Connection',
  connections: 'Connections',
  followers: 'Followers',
  follow: 'Follow',
  all_users: 'All Users',
  all_companies: 'All Companies',
  following: 'Following',
  unfollow: 'Unfollow',
  teams: 'Companies',
  reload: 'Reload',
  share_it: 'Share it',
  submit: 'Submit',
  terms_and_conditions: 'terms and conditions',
  copy_link: 'Copy Link',
  required_field: 'This field is required',
  user: 'User',
  public: 'Public',
  private: 'Private',
  invite: 'Invite only',
  email: 'Email',
  create: 'Signup',
  g2c: 'Built with the G2C Suite',
  not_member: 'Not a member?',
  reset: 'Reset password',
  writing_blockchain: 'Writing...',
  stripe:
    'We have just verified all the required information. When clicking on the button, you will be redirected to our partner to complete the operation you have requested',
  popup_blocked:
    'We need to redirect you to our partner Stripe, please allow popup from our website first.',
  subject: 'Subject field is required',
  number_of_notifications: '99+',
  commission_type: {
    percent: '%',
  },
  funds: "You don't have enough funds.",
  payments: 'Impossible to process payment.',
  search: {
    placeholder: {
      default: 'Who or what are you looking for?',
      market: 'Search in {tab}',
    },
  },
  select: {
    users: 'You need to connect with other users first.',
  },
  description: {
    contribution_wiva: `Contribution from @{from} to ${MARKETPLACE_NAME} Marketplace`,
    direct_payment: 'Direct payment from @{from} to @{to}',
    connection: 'Connection payment from @{from} to @{to}',
    message: 'Message payment from @{from} to @{to}',
    room: 'Room booking from @{from} to @{to}',
    tree: 'Message blockchain writings (Metanet)',
    userConnection: 'Connection blockchain writings (Metanet)',
    userMessage: 'Message blockchain writings (Metanet)',
    refund: 'Refund from operation - {date}',
  },
  errors: {
    get_user: `Error connecting with ${MARKETPLACE_NAME} Marketplace, Login again if persist.`,
    already_logged_in:
      'User already logged in, please logout before trying to Login again.',
    not_logged_in: 'Session Expired or not logged in',
    unexpected: 'Unexpected Error',
    network: "Can't connect with the network",
  },
  info: {
    send: 'To send {amount} you accept a total funding of {total}',
    connection:
      'To connect sending {amount} you accept a total funding of {total}',
    message: 'Total funding: {total}',
    upload_object: 'Total funding: {total}',
  },
  recommendations: 'Recommendations',
  dates: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    now: 'Now',
    yesterday: 'Yesterday',
  },
  copy: 'copy',
  copies: 'copies',
  tickets: 'tickets',
  preview: 'Preview',
  tags: {
    ['token-type']: {
      physical: 'Physical product',
      digital: 'Digital product',
      ticket: 'Ticket',
      service: 'Service',
      investment: 'Investment',
    },
    shipping: {
      delivery: 'Delivery',
      pickup: 'Pick-up',
      shipping: 'Shipping not defined',
    },
    ['token-status']: {
      buy_now: 'On Sale',
      auction: 'On Auction',
      available: 'Available to Offer',
      deleted: 'Deleted',
      expired: 'Expired',
      processing: 'Processing',
      others: 'Owned by others',
      delisting: 'Delisting',
      pending: 'Pending Validation',
      not_validated: 'Not Validated',
    },
  },
  timer: {
    days: {
      plural: '{unit} days',
      singular: '{unit} day',
    },
    hours: {
      plural: '{unit} hours',
      singular: '{unit} hour',
    },
    minutes: {
      plural: '{unit} minutes',
      singular: '{unit} minute',
    },
    seconds: {
      plural: '{unit} seconds',
      singular: '{unit} second',
    },
  },
  payment_methods: {
    use_card: 'Use',
    remove_card: 'Delete',
    number: '{funding} Card ending in {last4}',
    expedited: 'Expiry {month}/{year}',
    saved: 'Saved on {created}',
    funding: {
      credit: 'Credit',
      debit: 'Debit',
    },
    errors: {
      select: 'Please select a saved card first',
    },
  },
  g2c_in_process: {
    new_working: {
      title: 'Just a moment {user}',
      info: 'At this moment there is a process working, just wait for a moment or untill we inform you that this process is done and try again',
    },
    warning:
      'There is a process working, if you leave the page you may lose information. Are you sure you want to leave the current page?',
  },
  terms: {
    t_title: `${MARKETPLACE_NAME} Marketplace terms of service`,
    p_title: `${MARKETPLACE_NAME} Marketplace Privacy Policy`,
  },
};
