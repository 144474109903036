
import { EventBus } from '~/components/eventBus/EventBus';
import { mapGetters } from 'vuex';
import {
  CustomError,
  CUSTOM_ERROR,
  USER_ACTION_VERIFY,
  USER_OPTION_COMPANY,
} from '~/vars/api';
import BaseBtn from '../base/BaseBtn.vue';
export default {
  components: {
    BaseBtn,
    BecomeMinterStep_1: () =>
      import(
        `~/components/minters/become-minters-steps/BecomeMinterStep_1.vue`
      ),
    BecomeMinterStep_2: () =>
      import(
        `~/components/minters/become-minters-steps/BecomeMinterStep_2.vue`
      ),
    BecomeMinterStep_3: () =>
      import(
        `~/components/minters/become-minters-steps/BecomeMinterStep_3.vue`
      ),
  },
  data() {
    return {
      url: '',
      loading: false,
      loadingStatus: false,
      errorMsg: null,
      stripeStatus: null,
      selectedCountry: null,
      step: 0,
      handlers: {
        initProcess: this.$api.pGatewayProvider.becomeMinter,
        pending: this.$api.pGatewayProvider.minterRefresh,
        verified: this.$api.user.retrieveMinterData,
        refreshProcess: this.$api.pGatewayProvider.minterRefresh,
      },
    };
  },
  computed: {
    ...mapGetters({
      modalData: 'ui/modalData',
      showMinterModal: 'ui/showMinterModal',
    }),
    shouldShowModal: {
      set() {
        EventBus.$emit('update-stripe');
        this.$store.dispatch('ui/showMinterModal', {
          status: false,
          data: {},
        });
      },
      get() {
        return this.showMinterModal;
      },
    },
    USER_ACTION_VERIFY() {
      return USER_ACTION_VERIFY;
    },
    becomeMinterByStep() {
      return this.step === 0 ? null : `BecomeMinterStep_${this.step}`;
    },
    userOption() {
      return this.$auth.user.data.user_option;
    },
    btnLabel() {
      return this.stripeStatus === 'success'
        ? this.$t('stripe.onboarding.minter.button')
        : this.$t('stripe.onboarding.cta.button');
    },
    isCompany() {
      return this.$auth.user.data.user_option === USER_OPTION_COMPANY;
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    showMinter() {
      return (
        this.$auth.loggedIn &&
        (!this.onlyCompanyMinter || (this.onlyCompanyMinter && this.isCompany))
      );
    },
  },
  methods: {
    async setStripeUrl() {
      this.errorMsg = null;
      if (this.step === 0 && this.stripeStatus === 'become_minter')
        this.nextStep();
      else {
        this.loading = true;
        try {
          if (this.stripeStatus === 'verifying') return;
          const url = `${window.location.origin}/redirecting`;
          const newTab = window.open(url, '_blank');
          if (
            newTab === null ||
            newTab === undefined ||
            newTab.closed ||
            typeof newTab.closed == 'undefined'
          ) {
            newTab.close();
            this.errorMsg = this.$t('base.popup_blocked');
            return;
          }
          const stripeUrl = await this.getStripeUrl();

          if (stripeUrl.error) throw new CustomError(stripeUrl.message);

          if (stripeUrl.data.verification_status === 'pending') {
            newTab.close();
            throw new CustomError(this.$t('stripe.onboarding.errors.pending'));
          } else {
            newTab.location.href = stripeUrl.data.url;
          }
        } catch (error) {
          this.errorMsg =
            error.name === CUSTOM_ERROR
              ? error.message
              : this.$t('stripe.onboarding.errors.generic');
        } finally {
          this.loading = false;
        }
      }
    },
    async setStripeStatus() {
      this.loadingStatus = true;
      try {
        this.stripeStatus = await this.$api.pGatewayProvider.minterCheck();
      } catch (error) {
        if ({ error })
          this.errorMsg = this.$t('stripe.onboarding.errors.refreshing');
      } finally {
        this.loadingStatus = false;
      }
    },
    getStripeUrl() {
      if (this.modalData.minterRefreshProcess) {
        return this.handlers.refreshProcess();
      } else if (this.stripeStatus === 'become_minter') {
        return this.handlers.initProcess(
          this.selectedCountry ? this.selectedCountry.code : undefined,
        );
      } else if (
        ['continue_onboarding', 'pending'].includes(this.stripeStatus)
      ) {
        return this.handlers.pending();
      } else if (this.stripeStatus === 'success') {
        return this.handlers.refreshProcess();
      }
    },
    async nextStep() {
      this.step++;
      if (this.step === 2) await this.setStripeStatus();
    },
    stepBack() {
      this.errorMsg = null;
      this.step--;
    },
  },
  mounted() {
    if (
      typeof this.$auth.user.data.verification_status !== 'undefined' &&
      this.$auth.user.data.verification_status !== null &&
      this.$auth.user.data.verification_status !== 'none'
    )
      this.setStripeStatus();
    else this.stripeStatus = 'become_minter';
  },
};
