import { render, staticRenderFns } from "./AccountAddFundsModal.vue?vue&type=template&id=6eafa634&scoped=true&"
import script from "./AccountAddFundsModal.vue?vue&type=script&lang=js&"
export * from "./AccountAddFundsModal.vue?vue&type=script&lang=js&"
import style0 from "./AccountAddFundsModal.vue?vue&type=style&index=0&id=6eafa634&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eafa634",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/tmp/build_51aa5b7f/components/base/BaseBtn.vue').default,BaseIconNew: require('/tmp/build_51aa5b7f/components/base/BaseIconNew.vue').default})
