import { render, staticRenderFns } from "./LayoutBottomBar.vue?vue&type=template&id=24587edf&"
import script from "./LayoutBottomBar.vue?vue&type=script&lang=js&"
export * from "./LayoutBottomBar.vue?vue&type=script&lang=js&"
import style0 from "./LayoutBottomBar.vue?vue&type=style&index=0&id=24587edf&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutVerifyBtn: require('/tmp/build_51aa5b7f/components/layout/LayoutVerifyBtn.vue').default,LayoutMarketBtn: require('/tmp/build_51aa5b7f/components/layout/LayoutMarketBtn.vue').default,LayoutTopBarWalletBtn: require('/tmp/build_51aa5b7f/components/layout/LayoutTopBarWalletBtn.vue').default,LayoutNotificationsBtn: require('/tmp/build_51aa5b7f/components/layout/LayoutNotificationsBtn.vue').default,LayoutTopBarAccountBtn: require('/tmp/build_51aa5b7f/components/layout/LayoutTopBarAccountBtn.vue').default})
