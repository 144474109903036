export default class G2CLib {
  constructor() {
    this.useWorker = typeof Worker !== 'undefined';
    this.pendingRequests = {};

    if (this.useWorker) {
      this.worker = new Worker('/G2CLibWorker.js');

      this.worker.onmessage = (event) => {
        this.handleWorkerMessage(event);
      };
    } /*else {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://api.gate2chain.com:1975/libs/g2clib.min.js';
            document.body.appendChild(scriptElement);
        }*/
  }

  executeWorker(fn, args, useCallback) {
    return new Promise(async (resolve, reject) => {
      const requestId = Date.now().toString();
      this.pendingRequests[requestId] = { resolve, reject };
      console.log('pre-send-worker', args);
      this.worker.postMessage({ fn, args, useCallback, requestId });
    });
  }

  async executeLocally(fn, args, useCallback) {
    try {
      console.log('LOCALLY', fn, args, useCallback);
      if (typeof useCallback !== 'undefined' && useCallback === true) {
        return new Promise((resolve, reject) => {
          try {
            window[fn](...args, (result) => {
              console.log('executeLocally', result);
              resolve(result);
            });
          } catch (error) {
            console.log('executeLocally', { error });
            reject(error);
          }
        });
      } else {
        const result = await window[fn](...args);
        console.log({ result });
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  execute(fn, args, useCallback = false) {
    if (this.useWorker) {
      return this.executeWorker(fn, args, useCallback);
    } else {
      return this.executeLocally(fn, args, useCallback);
    }
  }

  handleWorkerMessage(event) {
    console.log('WORKER RESPONSE', event);
    const { requestId, result, error } = event.data;
    const request = this.pendingRequests[requestId];

    if (!request) return;
    delete this.pendingRequests[requestId];
    const { resolve, reject } = request;
    if (error) {
      reject(error);
    } else {
      resolve(result);
    }
  }

  g2c_getRandomWords() {
    return this.execute('g2c_getRandomWords');
  }
  g2c_exchangeRates(...args) {
    return this.execute('g2c_exchangeRates', [...args]);
  }
  g2c_getBalance(...args) {
    return this.execute('g2c_getBalance', [...args]);
  }
  g2c_walletDirectSend(...args) {
    return this.execute('g2c_walletDirectSend', [...args]);
  }
  g2cclient_walletConditionalPropose(...args) {
    return this.execute('g2cclient_walletConditionalPropose', [...args], true);
  }
  g2c_walletConditionalReject(...args) {
    return this.execute('g2c_walletConditionalReject', [...args], true);
  }
  g2c_walletConditionalAccept(...args) {
    return this.execute('g2c_walletConditionalAccept', [...args], true);
  }
  g2cclient_createUserObjectTree(...args) {
    return this.execute('g2cclient_createUserObjectTree', [...args]);
  }
  g2c_getUserObject(...args) {
    return this.execute('g2c_getUserObject', [...args]);
  }
  g2c_getUserObjectList(...args) {
    return this.execute('g2c_getUserObjectList', [...args]);
  }
  g2c_getUserObjectHistoric(...args) {
    return this.execute('g2c_getUserObjectHistoric', [...args]);
  }
  g2cclient_createUserObject(...args) {
    return this.execute('g2cclient_createUserObject', [...args]);
  }
  g2cclient_updateUserObject(...args) {
    return this.execute('g2cclient_updateUserObject', [...args]);
  }
  g2cclient_tokenGet(...args) {
    return this.execute('g2cclient_tokenGet', [...args], true);
  }
  g2cclient_tokenCreate(...args) {
    return this.execute('g2cclient_tokenCreate', [...args], true);
  }
  g2cclient_tokenTransfer(...args) {
    return this.execute('g2cclient_tokenTransfer', [...args], true);
  }
  g2cclient_tokenConditionalPropose(...args) {
    return this.execute('g2cclient_tokenConditionalPropose', [...args], true);
  }
  g2c_tokenConditionalAccept(...args) {
    return this.execute('g2c_tokenConditionalAccept', [...args], true);
  }
  g2c_tokenConditionalReject(...args) {
    return this.execute('g2c_tokenConditionalReject', [...args], true);
  }
  g2cclient_tokenRedeem(...args) {
    return this.execute('g2cclient_tokenRedeem', [...args], true);
  }
}
