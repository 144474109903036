import { render, staticRenderFns } from "./UserCardsAvatar.vue?vue&type=template&id=77a22c3e&"
import script from "./UserCardsAvatar.vue?vue&type=script&lang=js&"
export * from "./UserCardsAvatar.vue?vue&type=script&lang=js&"
import style0 from "./UserCardsAvatar.vue?vue&type=style&index=0&id=77a22c3e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/tmp/build_51aa5b7f/components/user/UserAvatar.vue').default,BaseVerifyIcon: require('/tmp/build_51aa5b7f/components/base/BaseVerifyIcon.vue').default})
