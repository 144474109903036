import { info } from 'sass';
import { MARKETPLACE_COMMISSION, MARKETPLACE_NAME } from '~/vars/api';
export default {
  base: {
    tabs: {
      marketplace: 'Market',
      myofferings: 'My Offerings',
      mybids: 'My Bids',
    },
    no_logged: {
      title: 'You need to be logged to see this page',
      subtitle: 'If you dont have an account create one!',
    },
    copy: 'Copy',
    copies: 'Copies',
    owner: 'owner',
    creator: 'minter',
    description: 'description',
    seller_notes: 'seller notes',
    view_collection: 'View Collection',
    search: 'with that specification',
    all_products: 'All products',
    all_collections: 'All collections',
    all_companies: 'All Stores',
    filters: {
      all: 'All',
      my_creations: 'My creations',
      my_listings: 'My Listings',
      invitations: 'Invitations',
      products: 'Products',
      collections: 'Collections',
      creators: 'Creators',
      favorites: 'Favorites',
      art_events: 'Art Events',
      music: 'Music',
      gourmet: 'Gourmet',
      gaming: 'Gaming',
      travel: 'Travel',
      photo: 'Photo',
      education: 'Education',
      collectibles: 'Collectibles',
      auctions: 'Auctions',
      search: 'Search in Market',
      // filters new
      access: {
        label: 'Access',
        options: {
          any: {
            label: 'Any offering',
          },
          public: {
            label: 'Public',
          },
          invitations: {
            label: 'Invitations',
          },
        },
      },
      source: {
        label: 'Source',
        options: {
          any: {
            label: 'Any product',
          },
          collections: {
            label: 'Collections',
          },
          creators: {
            label: 'Creators',
          },
        },
      },
      categories: {
        label: 'Categories',
        options: {
          any: {
            label: 'All categories',
          },
          art: {
            label: 'Art',
          },
          music: {
            label: 'Music',
          },
          fashion: {
            label: 'Fashion',
          },
          travel: {
            label: 'Travel',
          },
          membership: {
            label: 'Membership',
          },
          collectibles: {
            label: 'Collectibles',
          },
          entertainment: {
            label: 'Entertainment',
          },
          metaverse: {
            label: 'Metaverse',
          },
          gaming: {
            label: 'Gaming',
          },
          photo: {
            label: 'Photo',
          },
          education: {
            label: 'Education',
          },
        },
      },
      type: {
        label: 'Type',
        options: {
          any: {
            label: 'All types',
          },
          product_physical: {
            label: 'Physical product',
          },
          product_digital: {
            label: 'Digital Product',
          },
          ticket: {
            label: 'Ticket',
          },
          service: {
            label: 'Service',
          },
          investment: {
            label: 'Investment',
          },
        },
      },
      offer: {
        label: 'Offer',
        options: {
          any: {
            label: 'All offers',
          },
          auction: {
            label: 'Auction',
          },
          buy: {
            label: 'Fixed price',
          },
        },
      },
      creation: {
        label: 'By Creation',
        options: {
          any: {
            label: 'Any creator',
          },
          mycreation: {
            label: 'My creations',
          },
          other: {
            label: 'Others creations',
          },
        },
      },
      status: {
        label: 'Status',
        options: {
          any: {
            label: 'All',
          },
          active: {
            label: 'Active',
          },
          sold: {
            label: 'Sold',
          },
          closed: {
            label: 'Closed',
          },
        },
      },
    },
  },
  events: {
    buynow: 'Sale',
    mint: 'Minted',
    transfer: 'Transferred',
    auction: 'Auction Won',
    redeem: 'Deleted',
    ticket_transfer: 'Ticket Validated',
    royalty: 'Royalties',
    beneficiary: 'Beneficiary',
  },
  product: {
    base: {
      resell: {
        can: 'Can be resold/transferred',
        cannot: 'Non-transferable/Cannot be resold',
      },
      royalty: 'Royalty: {royalty}{type}',
      beneficiary:
        'Benefits: {benefits}{type} from a {royalty}{type} ({total}{type})',
      quantity: {
        label: 'Number items',
      },
      message_one_copy: {
        label: 'Price of 1 item',
        label_copy: 'Price of 1 copy',
        equivalent: 'Equivalent to {amount}',
      },
      message_total: {
        label: 'Total price',
        equivalent: 'Equivalent to {amount}',
      },
      generic_info: {
        copies: {
          title: 'Number of Copies',
          content: {
            plural: '{amount} of {amountMinted} copies',
            singular: '{amount} of {amountMinted} copy',
          },
        },
        resell: {
          title: 'Can be resold / transferred',
        },
        royalty: {
          title: 'Royalty',
          content: '{amount} {unit}',
        },
        beneficiary: {
          title: 'Beneficiary',
          content: '{amount} {unit}',
        },
        type: {
          title: 'Type of selling',
          content: {
            auction: 'Auction',
            buy: 'Fixed price',
          },
        },
        added_file: {
          title: 'file attached',
        },
      },
    },
    card: {
      ended: 'Processing transactions',
      closed: 'Closed',
      ending: {
        auction: 'Auction ends in',
        buy: 'Sale ends in',
      },
      offering_price: {
        auction: 'Current bid',
        buy: 'Price',
      },
      validation: {
        pending: 'Pending Validation',
        not_validated: 'Not validated',
        validated: 'Validated',
      },
      current_bid: 'Bid',
      price: 'Price',
      creator: 'Minter',
      seller: 'Seller',
      owner: 'Owner',
      both: 'Minter & Seller',
    },
    download: {
      label: {
        owner: 'Download',
        not_owner: 'This product has a file only onwer can download',
      },
    },
    share: {
      copied: 'Link copied',
      error: 'Error copying link',
    },
    dependency: {
      owner: 'since {date}',
      creator: 'minted {date}',
      minter: 'minted {date}',
    },
    remaining: {
      title: 'Sale ends on {date} at {hour}',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      header_text: '{remaining} left',
    },
    auction_action: {
      copies: 'Number copies',
      quick: 'quick bid',
      write: 'write bid',
      place: 'Place bid',
      confirm_modal: {
        title: 'Confirm bid',
        subtitle: 'Confirm your bid for {tokenTitle}',
        price: 'Your bid per copy',
        quantity: 'Quantity',
        total: 'Total',
        btn: 'Place bid',
      },
    },
    actions: {
      title: 'Actions',
      make: 'Make an offer',
      buy_now: 'Buy Now',
      bid: 'Bid',
      initial: 'Initial bid',
      status_ending: 'No more actions allowed',
      product: {
        available:
          'This product has no file and cannot be transferred nor listed',
        involved: 'Tokens owned by other users',
        redeemed: 'Tokens deleted',
      },
      offering: {
        auction: {
          open: 'Open',
          nobids: 'Processing winners...',
          closed: 'Closed',
          winner:
            'Processing substitute winners, you do not have substitute bids.',
          win_substitute:
            'Your have winners bids and for {substitute} substite(s) bid(s) still there is a chance...',
          substitute: 'Still there is a chance...',
          win_paid_substitute:
            'You still have a chance for {substitute} substite(s) bid(s) too',
        },
        buy: {
          open: 'Open',
          nobids: 'Processing pending buys...',
          closed: 'Closed',
          substitute: 'Still there is a chance...',
        },
      },
      leave: {
        label: 'Leave Offer',
        title: 'Leave',
        keep: 'Keep offer',
        leave: 'Leave offer',
        subtitle: {
          nobids: 'If you leave, you will lose possible Items won.',
          open: 'You can leave the auction.',
        },
      },
      order: {
        accepted: 'Pending for payment...',
        payment_intent_created:
          "You may make a payment intent, but doesn't paid",
        payment_intent_success:
          'Your payment has been successfull but funds have not been received',
        checkout_complete:
          'Payment session completed but funds have not been received',
        paid: 'Payment process successfull, pending to transfer product',
        fulfilled: 'Payment and transfer product successfull',
        cancelled: 'Payment cancelled',
        payment_expired: 'Time to pay expired',
        order_expired: 'Time to get product expired',
      },
      bid_status: {
        title: 'You have a bid: {status}',
        number: 'Copies',
        offer: 'Your Offer',
        last: 'Your bid',
        status: {
          winner: '{copies} Winner',
          active: {
            title: 'Active',
            copies: '{copies}',
          },
          substitute: {
            title: 'Substitute',
            copies: '{copies}',
          },
          expired: {
            title: 'Expired',
            copies: '{copies}',
          },
          copies: '{copies}',
          outbid: 'Outbid',
          notwinner: 'Not winner',
          cancelled: 'Cancelled',
        },
      },
    },
    offers: {
      title: 'Bids status',
      text_bid: '{text}: {amount}',
      text_buy_now: 'Buy now: {amount}',
      offer: 'Offer',
      min_bid: 'Min. bid',
      initial_bid: 'Initial bid',
      status: 'Status',
      available: 'Available',
      blocked: 'In process',
      bid: 'Bid',
      price: 'Price',
      bids: 'Bids',
      from: 'From',
      no_data: 'There are no bids',
      date: 'Date',
      bid_status: {
        active: 'Active',
        winner: 'Winner',
        expired: 'Expired',
        substitute: 'Substitute',
        outbid: 'Outbid',
        notwinner: 'Not winner',
        substitute_winner: 'Subs. Winner',
      },
    },
    copies: {
      title: 'Copies',
      status: 'Status',

      copy: 'Copy',
      no_data: 'There are no copies availables',
      date: 'Sell Date',
      copy_status: {
        available: 'Available',
        blocked: 'In process',
        sold: 'Sold',
        not_available: 'Not available',
      },
    },
    transaction: {
      title: 'public tx',
      see: 'See tx',
      contract: {
        label: 'Create Contract',
      },
      issue: {
        label: 'Issue Items',
      },
    },
    history: {
      title: 'Product History',
      event: 'Event',
      amount: 'Price',
      from: 'From',
      to: 'To',
      date: 'Date',
      tx: 'Tx',
      total: '{amount} (All copies)',
      input: 'Copy Number',
    },
    terms: {
      title: 'Legal Terms',
    },
    more_owner: {
      title: 'More from this owner',
    },
    shipping: {
      delivery: {
        title: 'Delivery list of Countries',
        subtitle: 'This Physical Product will be shipped to this Countries:',
      },
      pickup: {
        title: 'Pick-up address',
        subtitle:
          'You can pick-up your physical product in this address showing your Item.',
        instructions: 'Instructions:',
      },
    },
  },
  make_offer: {
    title: 'Make an offer',
    subtitle:
      'Enter amount above minimum bid to make an offer sending your bid.',
    button: 'Send my bid',
    min_bid: 'Minimum bid: {amount}',
    initial_bid: 'Initial bid: {amount}',
    success: 'Bid sent ',
    total: 'In case of winning bid you will pay: {amount}',
    copies_price: {
      title: 'Number of copies',
      label: 'Your bid',
    },
    fields: {
      amount: 'Enter your offer',
      quantity: 'For how many copies do you want to make the offer?',
    },
    cannot_participate:
      "You are the minter of this product so you can't receive again any copy you would have sold or transferred before",
    validation: {
      amount: 'Please enter an amount higher than the minimum bid',
      quantity: 'Please enter a valid number of copies',
      multi_copy: 'Seller only permits bid for 1 copy at time',
      rebid:
        'You only can make an offer for the same number of copies that you had made already',
    },
    errors: {
      generic: 'Error sending bid, please try again',
      increase:
        "Your bid hasn't saved because another user has bid the same amount or higher before you. Please try increasing your bid.",
    },
  },
  buy_product: {
    title: 'Buy product',
    subtitle:
      'Buy now a copy of this product. Select payment method if seller has given you different options to choose.',
    card: {
      no_sats: 'Buy now',
      sats: 'Buy by card',
    },
    wallet: {
      fiat: 'Buy by Wallet',
      no_fiat: 'Buy now',
    },
    copy: '{copies} {units} price:',
    price: '{amount}',
    success:
      'Product Bought  We will send you a notification when you have it available',
    fields: {
      quantity: 'Copies to buy',
    },
    pay: 'Pay',
    cannot_participate:
      "You are the minter of this product so you can't receive again any copy you would have sold or transferred before",
    shipping: {
      country: {
        title: 'Select the country where the shipment will be sent',
        label: 'Country',
      },
      address: {
        title: 'Shipping address',
      },
    },
    buy_sats: {
      royalties: {
        fiat: 'This product has a Royalty which Minter will receive in Sats',
        sats: 'This product has a Royalty which Minter will receive in Sats',
      },
      deposit_already: 'You already have deposit in',
      deposit:
        'Deposit to make the offer: {deposit} ({minWallet} min in wallet to process)',
      deposit_fiat:
        'Deposit to make the offer: {deposit} ({symbol}{depositFiat}) {minWallet} min in wallet to process)',
      fullprice: {
        fiat: 'To buy with Sats this product you will need to have in your wallet {amount}',
        sats: 'To buy with Sats this product you will need to have in your wallet {amount}',
      },
    },
    validation: {
      quantity: 'Please enter a valid number of copies',
      multi_copy: 'Seller only permits buy 1 copy at time',
    },
    errors: {
      create_bids: 'Error creating payments for bids',
      royalty: 'Royalty payment error',
      beneficiary: 'Beneficiary payment error',
      generic: 'Error buying product, please try again',
      stripe: `There is an error with stripe, please contact ${MARKETPLACE_NAME} Marketplace Support Team`,
      popup_blocked:
        'We need to redirect you to our partner Stripe to secure you payment, please allow popup from our website first.',
      booked:
        'At this moment this product is booked, please try again in five minutes',
    },
  },
  auction_pay: {
    title: 'Pay',
    deposit_reminder:
      'Remember you already has paid {amount} as deposit which is going to be deducted in the payment process',
    card: {
      no_sats: 'Pay now',
      sats: 'Pay by card',
    },
    wallet: {
      fiat: 'Pay by Wallet',
      no_fiat: 'Pay now',
    },
    expirated: 'Sorry but your bid is expired',
  },
  checkout_session: {
    notification: {
      success: '{item} purchase successful!',
      error: 'Could be an error paying for an {item} item',
    },
    title: '{action} with {paymentMethod}',
    steps: {
      1: 'Copies & shipping',
      2: 'Royalty payment',
      3: 'Item payment',
    },
    steps_sats: {
      1: 'Copies & shipping',
      2: 'Royalty & Item Payment',
      3: 'Item Payment',
    },
    confirm_modal: {
      title: 'Cancelling transaction',
      subtitle:
        'Are you sure you want to cancel this transaction losing all info and payments done?',
      cancel: 'Stay and continue',
      continue: 'Cancel and leave',
    },
    action: {
      buy: 'Buy',
      auction: 'Pay',
    },
    payment_method: {
      card: 'credit card',
      wallet: 'Sats',
    },
    button: {
      buy: 'Buy now',
      auction: 'Pay items won',
      save: 'Save and Pay',
      honoring: 'Pay and continue',
      selling: 'Pay and finish',
    },
    info: {
      try_new: 'Here you can pay with a new Payment Method',
    },
    fields: {
      summary: {
        title: 'Item Summary',
        copies: {
          amount: '{amount} {type}',
        },
        costs: {
          subtotal: 'Subtotal',
          total: 'Total',
          royalty: 'Royalty {amount}{unit}',
          beneficiary: 'Beneficiary {amount}{unit}',
        },
      },
      copies: {
        title: 'Copies',
        box: {
          title: 'Number of copies',
          hint: {
            buy: 'Number of copies you want to buy',
            auction: 'Number of copies you won',
          },
          label: {
            buy: 'Price',
            auction: 'Your bid',
          },
          supply: {
            label: 'Copies',
            info: '{amount} of {total}',
          },
        },
      },
      shipping: {
        delivery: {
          title: 'Shipping',
          country: {
            title: 'Country',
            hint: 'Select the country where the shipment will be sent.',
            info: 'See the list',
            warning:
              'This seller does not ship to this country, please select a different one',
          },
          address: {
            title: 'Shipping address',
            hint: 'Select the shipping address or add a new one',
          },
        },
        pickup: {
          title: 'Pick-up',
          label: 'Pick-up address',
          hint: 'You can pick-up your physical product in this address showing your item.',
        },
      },
      honor_selling: {
        title: 'Let’s honor the Creator and Get the Item',
        royalty: {
          title: 'Let’s honor the Creator!',
          user_label: 'Creator',
          price_label: '{amount}{unit} Royalty',
          subtitle:
            'The user who minted this item has set up a royalty. This royalty will go directly to the Creator.',
        },
        beneficiary: {
          title: 'Let’s honor the Creator!',
          user_label: 'Beneficiary',
          price_label: '{amount}{unit} Royalty',
          subtitle:
            'The user who minted this item has set up a royalty and a beneficiary on behalf of. This royalty will go directly to the beneficiary.',
        },
        seller: {
          user_label: 'Seller',
          price_label: 'Item',
        },
        success_modal: {
          title: {
            buy: 'Honoring successful and buy Item requested, we will send you a notification when is fulfilled',
            auction:
              'Honoring successful and item payment requested, we will send you a notification when is fulfilled',
          },
        },
      },
      honoring: {
        royalty: {
          title: 'Let’s honor the Creator!',
          user_label: 'Creator',
          price_label: '{amount}{unit} Royalty',
          subtitle:
            'The user who minted this item has set up a royalty. The royalty will go directly to the Creator and the payment will go directly to the seller and you’ll receive the item in your wallet.',
        },
        beneficiary: {
          title: 'Let’s honor the Creator!',
          user_label: 'Beneficiary',
          price_label: '{amount}{unit} Royalty',
          subtitle:
            'The user who minted this item has set up a royalty and a beneficiary on behalf of. This royalty will go directly to the beneficiary and the payment will go directly to the seller and you’ll receive the item in your wallet.',
        },
        amount_equivalent: 'Equivalent to {amount}',
        success_modal: {
          title: {
            buy: 'Honoring successful.',
            auction: 'Honoring successful.',
          },
        },
      },
      selling: {
        seller: {
          title: 'Last step: the item',
          user_label: 'Seller',
          price_label: 'Item',
          subtitle:
            'The payment will go directly to the seller and you’ll receive the item in your wallet.',
        },
        amount_equivalent: 'Equivalent to {amount}',
        success_modal: {
          title: {
            buy: 'Buy Item requested, we will send you a notification when is fulfilled',
            auction:
              'Item payment requested, we will send you a notification when is fulfilled',
          },
        },
      },
      payment_method: {
        card: {
          question: 'Save for future payments?',
          title: 'Credit card information',
          options: {
            true: 'Saved Card',
            false: 'New card',
          },
          confirm_modal: {
            title: 'Confirm Payment',
            subtitle: {
              user: 'You are going to use your payment method saved for this user',
              platform: `You are going to use your payment method saved for ${MARKETPLACE_NAME} Marketplace`,
            },
          },
        },
        wallet: {
          title: 'Wallet Payment',
        },
      },
      process: {
        payment: 'Loading payment info',
        charge: 'Processing payment',
        saved: 'Checking payment methods',
        remove_pm: 'Removing Payment Method Saved',
      },
      info: {
        stripe: {
          redirect:
            'You will be redirected to our partner Stripe to secure your payment.',
        },
        auction: {
          deposit_reminder:
            'Remember you already has paid {amount} as deposit which is going to be deducted in the payment process',
        },
      },
    },
    validation: {
      copies: 'You must select a valid number of copies',
      delivery_address: 'Please select or set a Postal Address to deliver',
      auction: {
        quantity: 'There are only {amount} copies availables',
        multi_copy: 'Seller only permits bid for 1 copy at time',
      },
      buy: {
        quantity: 'There are only {amount} copies availables',
        multi_copy: 'Seller only permits buy 1 copy at time',
      },
      payment: {
        authentication:
          'Saved Payment Method requires authentication, you need to fill out again',
        other:
          'It may be needed to fill again the payment method or try another one',
      },
    },
    errors: {
      generic: 'Error paying',
      preorder: 'Error preparing order to pay',
      booked: 'At this moment this item has some copy of your pick booked',
      payment_submit: 'There has not been possible to submit payment',
      payment_intent: 'There was an error creating payment order',
      setup_intent: 'There was an error saving payment method',
      customer: 'There was an error creating customer',
      order_expired: 'Payment order expired, please try with a new item',
      invalid_honoring: 'Can not pay item with invalid honoring',
      unexpected: 'An unexpected error occurred',
      payment_methods: {
        remove: 'Unable to remove payment method',
        no_such: 'Payment method not found',
      },
      wallet: {
        honoring: {
          honoring: "You don't have enought funds to pay the honoring",
          selling:
            'You have enought funds to pay the honoring but not for the Item in the next step',
        },
        selling: {
          selling: "You don't have enought funds to pay the Item",
        },
        honor_selling: {
          honoring: "You don't have enought funds to pay the honoring",
          selling:
            'You have enought funds to pay the honoring but not for the Item',
        },
      },
    },
  },
  create_product: {
    label: 'Mint',
    title: 'Mint',
    become_minter: 'Become a minter',
    subtitle:
      "By creating a new Item you're issuing a digital asset in your name",
    select_kind: 'Select what kind of Item you want to mint',
    option_information: '{option} Information',
    collections: 'Collections',
    options: {
      product: 'Product',
      service: 'Service',
      ticket: 'Ticket',
      investment: 'Investment',
      ticket_service: 'Ticket/service',
    },
    select_product: 'Type of Product',
    shipping: 'Shipping',
    ticket: 'Ticket Redeem Information',
    legal_terms: 'Legal Terms',
    resell: 'Resale',
    price: 'Price',
    button: {
      create: 'Mint Item',
      preview: 'Preview',
    },
    handle_symbols: {
      title: 'Create new product or reuse a created one?',
      subtitle:
        "By creating a new Product you're issuing a digital asset in your name but if you already have created a Product and you want create more copies you can select it and reuse the same information ",
      success: 'Product information loaded successfully  ',
      fields: {
        select: {
          label:
            'Select an already created Product to confirm if you want to load its information',
        },
        select_button: 'Load full information',
        create_button: 'Create a new Product',
      },
    },
    preview: {
      title: 'This item will be created',
      subtitle:
        'Please double check all the information you have provided, as it will be created, it can’t be modified.',
    },
    fields: {
      image: {
        title: 'Main Public Image',
        alt: 'Main Public Image',
        hint: 'Maximum size 8MB',
      },
      more_files: {
        title: 'Add more public images (optional)',
        alt: 'Add morde Public images',
        hint: 'Maximum size 8MB each. Max 10 images',
      },
      name: {
        title: 'Title',
        hint: 'Include keywords that buyers would use to search for your item',
        placeholder: 'Write the title of your item',
      },
      quantity: {
        title: 'Copies',
        hint: 'Number of copies you want to mint. Max 200 copies.',
        preview: {
          title: 'Number of {type}',
          info: '{amount} {type}',
        },
      },
      description: {
        title: 'Description',
        hint: 'Describe your item, write all the characteristics',
        placeholder: 'Write about your item',
      },
      add_file_v2: {
        title: 'Add a file ({optional})',
        hint: 'Maximum size: 20MB. Files supported: Doc, PDF, Image, Audio, Video',
      },
      add_collection_v2: {
        title: 'Add this Item to a collection or create a new one ({optional})',
      },
      type_product: {
        title: 'Is this product physical or digital?',
        options: {
          physical: 'Physical',
          digital: 'Digital',
        },
        hints: {
          physical: 'A tangible item that you will deliver to buyers',
          digital: 'A digital file that buyers will download',
        },
      },
      shipping: {
        deliver: {
          label: 'I would deliver this product',
          select_country: 'Select the countries you can ship your product',
          clear_countries: 'Clear all',
          multiple_countries: '... ({amount})',
        },
        not_deliver: {
          label: 'Pickup',
          title: 'Pick up address',
          hint: 'Enter the address where the product is to be picked up',
        },
      },
      product: {
        alt: 'Product file',
        hint: 'Upload product file',
        info: `File name: {name}
        Size: {size}
        Extension: {extension}`,
      },
      symbol: 'Symbol',
      ticket: {
        label: '{tokenOption} options',
        hint: 'Select expiration date and user who will redeem them',
        placeholder: 'Valid: {date} - Redeemer: @{user}',
        handle: 'User (Handle)',
        title: 'Ticket options',
        fields: {
          redeemer: {
            title: 'Redeemer',
            info: 'Trasform the copies of this product in tickets. You must select an user as Redeemer of this tickets. This user will need to read a QR Code to validate tickets from users who use your tickets in order to redeem them',
          },

          date: {
            title: 'Expiration date',
            placeholder: 'Select a day',
            info: 'These tickets must have an expiration date and hour, till then will be valid to redeem and will be expired after. Please take care to place the correct day and time to not leave tickets out before event or service ends',
          },
        },
        preview: {
          title: 'Each copy of this product is a ticket',
          info: 'Valid until: {date}  -  Redeemer: @{user}',
        },
        added: 'Redeemer added',
        subtitle: 'Select the user that will redeem your tickets',
        select_button: 'Continue',
        horizontal_button: {
          title: {
            filled: 'Redeemer: @{user}',
            add: 'Tickets Redeem Info',
          },
          subtitle: {
            add: 'Select the Redeemer and the expiration date',
            filled: 'Expiration dat: {date}',
          },
        },
      },
      add_file: {
        label: 'Add a file ({option})',
        title: 'Add file',
        hint: {
          upload: `Upload a file on your ${MARKETPLACE_NAME} Marketplace directory`,
          select: `Select a file on your ${MARKETPLACE_NAME} Marketplace directory`,
          no_objects: `You don’t have any file on your ${MARKETPLACE_NAME} Marketplace directory. Start uploading!`,
        },
        options: {
          true: 'Upload',
          false: 'Select from your directory',
        },
        upload_file: {
          browse: 'Browse',
          hint: 'Maximum file size 20MB',
        },
        pricing: `Price to upload this file on the ${MARKETPLACE_NAME} Marketplace directory`,
        name: 'Name of the file',
        feedback: `Please wait. Checking file from your ${MARKETPLACE_NAME} Marketplace directory. This may take a moment.`,
        uploaded: 'File uploaded ',
        create_button: {
          filled: '{amount} - Upload',
          add: 'Upload',
        },
        select: {
          label: 'Select from your file directory',
          placeholder: 'File name',
          no_objects: `You don’t have any file on your ${MARKETPLACE_NAME} Marketplace directory. Start uploading!`,
          download_btn: 'Download',
          select_btn: 'Select this file',
        },
        added: 'File added',
        horizontal_button: {
          title: 'File',
          subtitle: {
            add: 'Upload or select a file',
            filled: `Added file from your ${MARKETPLACE_NAME} Marketplace directory`,
            loading_creating: 'Uploading and selecting File',
          },
        },
        errors: {
          network: "Can't connect with the network",
          creation: 'Error uploading/selecting file',
        },
      },
      add_collection: {
        label: 'Add to a Collection ({option})',
        hint: 'Here you can create a Collection or select a previously created Collection where clasify your product with other products already created or to be created',
        title: "Collection's Name",
        description: "Collection's Description",
        create_button: 'Create Collection',
        select_button: 'Select Collection',
        select: {
          label: 'Select collection',
        },
        modules: {
          create: 'Create NEW Collection',
          select: 'Select Collection',
        },
        added: 'Added to Collection',
        btn: {
          add: 'Add to Collection',
          added: "Don't add to Collection",
        },
        image: {
          alt: 'Collection Image',
          hint: "Upload Collection's image",
        },
      },
      terms: {
        title: 'Legal Terms Examples',
        step1: {
          title:
            'Select one of the examples of Legal Terms according to the type of Item you mint',
        },
        step2: {
          title:
            "Fill in your company's data to adapt our template to your needs",
        },
        step3: {
          title: 'Edit as needed to adapt these legal terms to suit your style',
        },
        fields: {
          companyName: 'Company name',
          address: 'Address',
          companyId: 'Company registration number',
          companyName: 'Company name',
          mercantileRegistry: 'Mercantile Registry info',
          companyContact: 'Company contact',
          companyEmail: 'Company email',
          tokenName: "Item's name",
          tokenDescription: "Item's description",
          redemptionPeriod: 'Redemption period',
          pickupAddress: 'Pickup address',
          companyWeb: 'Company/Event Web Page',
          companyRights: 'Admission Rights',
          causeNotRefund: 'Causes of no refunding',
          cancelCauseRefund: 'Cancelation cause with refund',
          disclaimerLiability: 'Disclaimer and limitation of Liability',
          companyCountry: 'Company country',
          companyCity: 'Company City',
        },
        placeholder:
          'Write here the Terms & Conditions on how to use your Item',
        hint: 'Add your Terms & Conditions. If you don’t have ',
        buttons: {
          select: 'Select Legal Terms',
        },
        examples: {
          link: 'use a template',
        },
      },
      resell: {
        label: 'Allow resale',
        title: 'Allow buyers to resale your product?',
        hint: 'By allowing buyers to resale your product, those users will be able to create offerings or transfer bought copies to other users.',
        options: {
          true: 'Allow to resale',
          false: 'Not allow to resale',
        },
        preview_label: 'Can be resold/transfered',
      },
      royalty: {
        info_1: 'Add a royalty for every future transaction of this item.',
        info_2:
          'Share this royalty with another user/ Association by adding a beneficiary.',
        info_3:
          'Because the nature of a physical product it is not able to have a royalty of its transactions.',
        label: 'Royalty ({option})',
        handle: 'User (Handle)',
        title: 'Add Royalty fee',
        hint: 'By allowing buyers to resale your product you can add a royalty fee for each resale.',
        select_button: 'Confirm Royalty',
        added: 'Royalty added',
        preview: {
          title: 'Royalty',
          info: '{amount}{unit}',
        },
        horizontal_button: {
          title: {
            add: 'Royalty {royalty}',
            filled: '{royalty}{royaltyUnit} royalty',
          },
          subtitle: {
            add: 'Add royalty fee',
            filled: 'Percentage of each sell of your item',
          },
        },
        input: {
          label: 'Royalty Fee',
          hint: '{maxRoyalty} fee maximum',
        },
      },
      beneficiary: {
        label: 'Add a Beneficiary ({option})',
        title: 'Add a Beneficiary',
        select_beneficiary: 'Select beneficiary user',
        hint: 'The user beneficiary will recieve the 100% of your royalty',
        btn: 'Confirm Beneficiary',
        cancel: 'Dismiss Beneficiary',
        select_button: 'Confirm Beneficiary',
        added: 'Beneficiary added',
        handle: 'User (Handle)',
        placeholder:
          '{benefits}{benefitsUnit} of your {royalty}{royaltyUnit} of Royalty for @{user}',
        preview: {
          title: 'Beneficiary',
          info: '{amount}{unit} @{user}',
        },
        horizontal_button: {
          title: {
            add: 'Beneficiary',
            filled: '@{user} as beneficiary',
          },
          subtitle: {
            add: 'Add beneficiary',
            filled: '{benefits}{benefitsUnit} of your royalty',
          },
        },
        slider: {
          label: 'Benefits ({benefitsUnit} of your royalty)',
          hint: 'Percentage of your royalties',
        },
        user: {
          label: 'Select user',
          hint: `${MARKETPLACE_NAME} Marketplace user`,
        },
      },
      contact: {
        label: 'Contact email: {email}',
        hint: 'This email will be given to your buyers so they can contact you if something happens with your Product.',
      },
      price: {
        label: 'Full price for minting your item',
      },
    },
    validation: {
      image: {
        size: 'Image should be less than 8 MB ',
        type: 'Should have a valid image file type',
        required: "Please upload a public product's image",
        max_items: 'Max 10 Images',
        limit: 'You will pass your limit to upload images with this image',
      },
      add_file: {
        required:
          'In order to Mint a Digital Product you need to add the file that you can download when get the Digital Product.',
      },
      file: {
        size: 'File should be less than 20 MB ',
        type: 'Should have a valid image or pdf file type',
        limit: 'You will pass your limit to upload files with this file',
      },
      title: {
        required: 'Please enter a Title',
        limit: 'Limit 50 chars',
      },
      title_object: "Only accepts alphanumeric (a-z 0-9) and '-'.",
      symbol: 'Please enter a valid symbol',
      copies: {
        max: 'Max 200 copies',
        required: 'Please set a number of copies',
        limit: 'You have reached your limit to mint items',
      },
      description: {
        required: 'Please enter a description',
        limit: 'Limit 300 chars',
      },
      terms: "Please enter Terms & Conditions or 'None'",
      ticket: {
        required: 'Please add ticket options',
      },
    },
    errors: {
      form: {
        required: 'Please fulfill form to create product',
      },
      select: {
        generic: `Error getting object from your ${MARKETPLACE_NAME} Marketplace directory, please contact ${MARKETPLACE_NAME} Marketplace Support Team`,
      },
      create: {
        generic: `Error creating object in your ${MARKETPLACE_NAME} Marketplace directory, please contact ${MARKETPLACE_NAME} Marketplace Support Team`,
        one_file: 'We only can upload one file at a time',
        forbidden: 'You are not able to Mint.',
      },
      image: {
        generic: 'Error uploading Public Image',
      },
      mint: {
        generic: 'Error minting the Item',
        create: 'It was not possible to create the Item',
        register: 'It was not possible to register the Item',
      },
    },
    background_modal: {
      title: 'You are Mintin a new {name} {item}',
      info: `You can continue your navigation through ${MARKETPLACE_NAME} Marketplace meanwhile but please dont close the browser or tab, we will inform you when work is done. Thank you.`,
      error: {
        title: 'Error Mintin {name} {item}',
        info: 'There was an error: \n\n{message}\n\n Please try again or contact support.',
      },
      success: {
        title: '{name} Minted successfully',
        info: 'You own this new {name} {item}',
      },
    },
    confirm_disclaimer_blockchain:
      'Please double check that all the information you have provided is correct, as it will be created, it cannot be modified anymore.',
  },
  market_place: {
    title: 'All Offerings',
    info: {
      title: 'There is no product {filter} listed in Market',
    },
  },
  favorites: {
    title: 'Favorites',
  },
  my_offerings: {
    title: 'My Offerings',
    info: {
      title: "You don't have any product listed in Market ",
      subtitle: 'List some of your products  ',
    },
  },
  my_bids: {
    title: 'My Bids',
    info: {
      title: "You haven't done any bid.",
      subtitle: 'Go to all offerings and make an offer in a product',
    },
  },

  list_product: {
    label: {
      available: 'List to Market',
      expirated: 'List not possible',
    },
    title: 'List to Market',
    subtitle: 'Configure your Item for sale on the Market',
    summary: 'Item Summary',
    public_images: 'Public images',
    token_info: 'Item information',
    details: 'Offering Details',
    type: 'Type of Offering',
    collections: 'Collections (optional)',
    shipping: 'Shipping',
    access: 'Manage who can view this offer',
    categories: 'Categories (optional)',
    contact: 'Contact',
    pricing: 'Price',
    limit_option: 'Only can select one option of sale',
    success: 'Item listed to market successfully',
    warning: {
      all: 'Each ticket has its generated QR Code so you are not able to List them to Market.',
      some: 'Some tickets have their generated QR Code so you are able to List the rest to Market. You have {copies} copies availables',
    },
    header: {
      copies: 'Number of {type}: {amount}',
      quantity: '{type} minted: {amount}',
      resell: 'Can be resold/transfered: {resell}',
      royalty: 'Royalty: {amount}{unit}',
      beneficiary: 'Beneficiary: {amount}{unit} @{user}',
      redeemer: 'Redeemer: @{user}',
      expiration: 'Ticket expiration date: {date}',
    },
    fields: {
      min_bid: 'Min Bid/unit',
      copies: {
        title: 'Copies',
        hint: 'Number of copies you want to sell.',
        action: 'Select all',
        multicopy: 'Allow user take more than one copy at a time',
      },
      token_info: {
        title: 'Seller notes (optional)',
        placeholder: 'Add notes for Item sale',
      },
      expiration: {
        date: 'Expiration Date',
        time: 'Hour',
        title: 'Expiration date',
        placeholder: 'Select a day',
        hint: 'Select the date when the offer will end.',
        action: 'Select maximum date',
      },
      type_offering: {
        options: {
          true: 'Auction',
          false: 'Fixed price',
        },
      },
      allow_bid: {
        title: 'Minimum Bid',
        hint: 'Amount to start your auction',
        type: {
          label: 'Select type of auction',
          hint: 'Every auction participant must pay a deposit to make an offer. The winner will pay the rest of the total bid. If the winner don’t go along, you’ll receive the deposit and take the next winner in list.',
          deposit: 'Deposit',
          options: {
            fullprice: 'Full price',
            deposit: 'With Deposit',
          },
        },
        deposit: {
          label: 'Let more users be able to make offers',
          hint: `By checking this option we set the lower amount for deposit, Deposit is paid with Sats so ${MARKETPLACE_NAME} Marketplace advises that is better a low deposit to let the users make offers, but you can edit this amount as you please.`,
        },
        multi_copy: {
          label: 'Allow multibid',
          hint: 'By checking this option you allow users to bid for more than 1 copy',
        },
        details: {
          fee_amount: '-{amount}',
          fee_amount_equivalent: 'Equivalent to -{amount}',
          amount_equivalent: 'Equivalent to {amount}',
          fee: `${MARKETPLACE_NAME} Marketplace fee (${MARKETPLACE_COMMISSION}%)`,
          honoring: {
            royalties: 'Honor Minter royalty to @{honorNick}',
            beneficiary: 'Honor Minter beneficiary @{honorNick}',
          },
          total: 'Total minimum bid',
        },
      },
      allow_buy_now: {
        title: 'Price',
        hint: 'Fixed price to buy your Item',
        multi_copy: {
          label: 'Allow multi buy',
          hint: 'By checking this option you allow users to buy more than 1 copy at time',
        },
        details: {
          fee_amount: '-{amount}',
          fee_amount_equivalent: 'Equivalent to -{amount}',
          amount_equivalent: 'Equivalent to {amount}',
          fee: `${MARKETPLACE_NAME} Marketplace fee (${MARKETPLACE_COMMISSION}%)`,
          honoring: {
            royalties: 'Honor Minter royalty to @{honorNick}',
            beneficiary: 'Honor Minter beneficiary @{honorNick}',
          },
          total: 'Total Price you’ll recevied',
        },
      },
      buy_now: 'Fixed price',
      collection: {
        subtitle: 'Add to a collection or create a new one',
        more: {
          more: 'See more',
          less: 'See less',
        },
        create_card: 'Add a new collection',
        create_collection: {
          image: {
            title: 'Image of your Collection',
            hint: 'Size  1228x296 px',
          },
          name: {
            title: 'Title of the collection',
          },
          add_button: 'Add new collection',
        },
      },
      type_shipping: {
        options: {
          true: 'Delivery',
          false: 'Pickup',
        },
        delivery: {
          subtitle: 'Select the countries you can ship your product',
          available: 'AVAILABLE COUNTRIES',
          selected: 'SELECTED COUNTRIES',
          search: 'Search Countries',
          worldwide: 'Worldwide',
        },
        pick_up: {
          subtitle: 'Select the address of pickup',
          add_new: 'Add a new address',
          add_instructions: 'Add instructions',
        },
        handle_address: {
          remove: {
            title: 'Remove Address',
            info: 'Are you sure you want to remove this address from your list?',
          },
          edit: {
            button: 'Edit Address',
          },
          pickup: {
            title: 'Address of pickup',
            subtitle:
              'In this address buyers will be able to pickup their physical product.',
            option: 'pickup',
            button: 'Add address',
          },
          delivery: {
            title: 'Shipping address',
            subtitle:
              'This address will be able to delivery a physical product.',
            option: 'delivery',
            button: 'Add address',
          },
          fields: {
            country: {
              label: 'Country',
              placeholder: 'Select country',
            },
            fullname: {
              label: 'Full Name',
              placeholder: 'To whose attention?',
            },
            line1: {
              label: 'Address Line 1',
              placeholder: 'Write the complete address',
            },
            line2: {
              label: 'Address Line 2 (Optional)',
              placeholder: 'Anything else more to add to address',
            },
            postalcode: {
              label: 'Postal Code',
              placeholder: '(Zip Code)',
            },
            city: {
              label: 'City',
              placeholder: 'City',
            },
            stateprovince: {
              label: 'Region',
              placeholder: 'Region, State or Province',
            },
            instructions: {
              label: 'Instructions (opcional)',
              placeholder: 'Add comments or instructions of {option}',
            },
            save_default: {
              label: 'Save as your default address',
            },
          },
        },
      },
      category: {
        label: 'Category',
        subtitle: 'Select the categories for your offering',
        placeholder: 'Art, music, collectibles...',
      },
      contact_info: {
        subtitle:
          'This is the email your buyers will have to contact you if something happens with your Item.',
      },
      pricing: {
        info: 'Price for listing your offering',
      },
      access: {
        label: 'Select access for your product in market',
        options: {
          public: 'Public',
          private: 'Private',
        },
        search: {
          placeholder: 'Search in users',
          all: 'Select all',
          none: 'Sorry but no users found',
        },
        modal: {
          btn: 'Send Invitation',
          sending: 'Sending invitations...',
          title: 'Send invitations to users',
          users: '{total} users',
          connections: '{total} connections',
          success: 'Invitations sent!',
          error: 'Error sending invitations, please try again later',
        },
      },
      add_collection: {
        label: 'Add to a Collection ({option})',
        title_modal: 'Add to a Collection',
        hint: 'Here you can add a new Collection or select a previous Collection where clasify your listin',
        title: "Collection's Name",
        create_button: 'New Collection',
        select_button: 'Select Collection',
        select: {
          label: 'Select collection',
        },
        modules: {
          create: 'NEW Collection',
          select: 'Select Collection',
        },
        added: 'Added to Collection',
        btn: {
          add: 'Add to Collection',
          added: "Don't add to Collection",
        },
        image: {
          alt: 'Collection Image',
          hint: "Upload Collection's image",
        },
      },
      invitations: {
        handle: 'Participants',
      },
      terms: {
        placeholder: 'Legal Terms',
        hint: 'Terms & Conditions in wich you would like your Product to be used.',
        issuer_terms:
          'These are the Terms & Conditions set by Creator and they can not be modified.',
        examples: {
          link: 'See examples.',
          default:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus erat ut libero varius suscipit. Proin id cursus neque, at aliquet leo. Mauris ipsum nisl, lacinia a ornare ac, malesuada at ex. Proin elementum laoreet magna, eu tempus nunc malesuada non. Aenean tristique malesuada imperdiet. In hac habitasse platea dictumst. In suscipit lectus nisi. Nulla et pharetra lorem. Quisque porttitor at dui ac ultrices. Donec orci ligula, tristique non purus sed, convallis sollicitudin metus. Morbi vulputate euismod lacus, in sollicitudin metus eleifend nec. Vestibulum ac fermentum odio. Aliquam pretium mollis justo, vel elementum turpis iaculis a.',
        },
      },
    },
    alert_modal: {
      title: 'Ops, something happened',
      info: 'There was a conflict with some of the copies. You can continue listing {validTokens} of your Item.',
    },
    background_modal: {
      title: 'We are listing your offering',
      info: `You can continue your navigation through ${MARKETPLACE_NAME} Marketplace meanwhile but please dont close the browser or tab, we will inform you when work is done. Thank you.`,
      error: {
        title: 'Error listing {name} {item} Offering',
        info: 'There was an error: \n\n{message}\n\n Please try again or contact support.',
      },
      success: {
        title: 'Create {name} {item} Offering Success!',
        info: 'Your offering is now pending to be validated by support to be listed.',
      },
    },
    verify_modal: {
      title: 'You are almost ready',
      info: "In the next 24/48 hours we'll verify your offering so you can start selling. Thank you.",
    },
    validation: {
      deposit: 'Deposit amount required',
      date: 'Date required',
      time: 'Time required',
      future: 'Not earlier than 1 hour from now',
      limit: 'Not after maximum date and time',
      bid: 'Minimum bid value permited {amount} {currency} {satFiat}',
      copies: {
        min: 'Minimum 1 copy to list product',
        max: 'Only have {amount} copies available',
      },
      deposit: {
        fiat: 'Deposit must be {amount} {currency}',
        wallet: 'Deposit must be {amount} {satFiat}',
        required: 'Deposit Required',
      },
      image: {
        size: 'Image should be less than 8 MB ',
        type: 'Should have a valid image file type',
        required: "Please upload a public product's image",
      },
      collection: {
        required: 'We cannot work with collections without name',
      },
      address: {
        country: {
          required: 'Please select your Country',
        },
        fullname: {
          required: 'Please add your full name',
        },
        line1: {
          required: 'Please fill with your full address',
        },
        postalcode: {
          required: 'Please enter your Postal Code (Zip Code)',
        },
        city: {
          required: 'Please enter your City',
        },
        stateprovince: {
          required: 'Please enter your stateprovince, State or Province',
        },
        adding: 'Server Error adding new Address',
      },
      copies_availables: 'You only have {maxCopies} copies availables',
      expiration: 'Please enter a future date',
      now: 'Minimun price value permited 1 {currency}',
      category: 'Please select a category',
      terms: "Please enter Terms & Conditions or 'None'",
    },
    errors: {
      form: {
        required: 'There is at least one field that is required',
      },
      generic: 'Error listing product on Market',
      conditionals: 'It was not possible to prepare Items to be listed',
      cancelled: 'It was not possible to list to market, cancelled',
      collection_image: 'Error uploading collections image',
      collection_already: 'You already have a Collection with that title',
      rejecting: `Impossible to reject Items proposed to list, please contact ${MARKETPLACE_NAME} Marketplace Support`,
    },
    button: 'List to Market',
  },
  conditional_product: {
    accept: {
      label: 'Accept',
    },
    reject: {
      label: 'Dismiss',
    },
    pending: {
      label: 'Pending {userName}',
    },
  },
  delist_product: {
    label: 'Delist',
    title: 'Delist Offering',
    info: 'Are you sure you want to delist this offering?',
    accept_btn: 'Yes, delist',
    cannot:
      'You cannot delist this offering. To be able to delist an offering this need to be on Sale or on Auction without registered bids',
  },
  confirm_auction: {
    label: 'Confirm Auction Bids',
  },
  transfer_product: {
    label: 'Transfer',
    title: 'Transfer your Item',
    subtitle: 'Select the user and the number of copies you want to transfer',
    success: '{copies} {type} transferred successfully',
    warning: {
      all: 'Each ticket has its generated QR Code so you are not able to transfer them.',
      some: 'Some tickets have their generated QR Code so you are able to transfer the rest. You have {copies} copies availables',
      physical:
        'Remember that this Item is a physical product and therefore you will have to send the product to the new owner',
    },
    fields: {
      copies: 'Number of copies.',
      all: 'Select all copies',
      user: {
        label: 'Username',
        placeholder: 'Select user who transfer to',
      },
    },
    validation: {
      copies: 'Minimum 1 copy to transfer',
      copies_availables: 'You only have {maxCopies} copies availables',
    },
    errors: {
      not_transferred:
        "There was an error and it wasn't possible to transfer {copies} copies",
      generic: "There was an error and it wasn't possible to transfer",
      cannot_transfer:
        "Sorry but it's not possible to transfer to the user who is the minter of the article or service",
    },
    button: 'Transfer',
    background_modal: {
      title: 'You are Transferring {copies} of {name}',
      info: `You can continue your navigation through ${MARKETPLACE_NAME} Marketplace meanwhile but please dont close the browser or tab, we will inform you when work is done. Thank you.`,
      error: {
        title: 'Error Transferring {name}',
        info: 'There was an error: \n\n{message}\n\n Please try again or contact support.',
      },
      success: {
        title: '{name} Transferred successfully',
        info: '{copies} / {total} copies is now owned by the receiver',
      },
    },
  },
  redeem_product: {
    label: 'Delete',
    title: 'Delete your Item',
    subtitle: 'Delete the copies you want',
    success: 'Delete successfull ',
    fields: {
      select: 'Select copy to delete',
      all_copies: {
        label: 'Delete all copies',
        hint: 'Mark this to delete all copies at once.',
      },
    },
    validation: {
      copies: 'Minimum 1 copy to delete',
      copies_availables: 'You only have {maxCopies} copies availables',
    },
    errors: {
      not_redeemed:
        "There was an error and it wasn't possible to redeem {copies} copies",
      generic: "There was an error and it wasn't possible to redeem",
    },
    button: 'Delete',
    background_modal: {
      title: 'You are Deleting {copies} of {name}',
      info: `You can continue your navigation through ${MARKETPLACE_NAME} Marketplace meanwhile but please dont close the browser or tab, we will inform you when work is done. Thank you.`,
      error: {
        title: 'Error Deleting {name}',
        info: 'There was an error: \n\n{message}\n\n Please try again or contact support.',
      },
      success: {
        title: '{name} Deleted successfully',
        info: '{copies} copies deleted permanently',
      },
    },
  },
  show_product: {
    btn: {
      label: {
        show: 'Show in profile',
        hide: 'Hide from profile',
      },
      tooltip: {
        show: 'Make it public',
        hide: 'Make it private',
      },
    },
  },
  stripe: {
    base: {
      timer: 'In {timer} {time} this page will be closed',
      hours: 'hours',
      minutes: 'minutes',
      seconds: 'seconds',
      second: 'second',
    },
    purchase: {
      success: {
        title: 'Great! You got a new Item',
        subtitle: `You can close this tab and go to the already opened ${MARKETPLACE_NAME} Marketplace session to see your new Item`,
      },
      cancel: {
        title: 'Transaction cancelled',
        subtitle: 'Payment and order not completed',
        info: `Close this tab and go to already opened ${MARKETPLACE_NAME} Marketplace session to continue navigation.`,
      },
    },
  },
};
