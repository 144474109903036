
import {
  CONNECTION,
  CONNECTION_STATUS_ACCEPTED,
  CONNECTION_STATUS_NO_CONNECTION,
  CONNECTION_STATUS_PENDING,
  CONNECTION_STATUS_PRECHARGED,
  CONNECTION_STATUS_PRECREATED,
  CONNECTION_STATUS_PREPAID,
  CONNECTION_STATUS_PRESHARED,
  CONNECTION_STATUS_REJECTED,
  USER_OPTION_DEFAULT,
} from '~/vars/api';
import { mapActions, mapGetters, mapState } from 'vuex';
import { TOGGLE_SHOW_USER_MODAL } from '~/vars/store/mutations';
import UserMixinHandlerLoggedUser from '~/components/user/UserMixinHandleLoggedUser.vue';
import VerifyEntityButton from '~/components/session/VerifyEntityButton.vue';
import G2cProcessMixinHandler from '~/components/commons/G2cProcessMixinHandler.vue';
import UserCardsBtnsFollow from './UserCardsBtnsFollow.vue';
import UserCardsBtnsUnfollow from './UserCardsBtnsUnfollow.vue';
import { handleErrors } from '~/utils/functions';
export default {
  mixins: [UserMixinHandlerLoggedUser, G2cProcessMixinHandler],
  components: {
    VerifyEntityButton,
    UserCardsBtnsFollow,
    UserCardsBtnsUnfollow,
    UserPendingRequestBtns: () =>
      import('~/components/user/cards/btns/UserPendingRequestBtns.vue'),
    ButtonConnectModal: () =>
      import('~/components/user/cards/btns/ButtonConnectModal.vue'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    btnsClasses: {
      type: String,
      default: '',
    },
    showDisconnectBtn: {
      type: Boolean,
      default: false,
    },
    showProgressBar: {
      type: Boolean,
      default: false,
    },
    showVerify: {
      type: Boolean,
      default: false,
    },
    parentNode: {
      type: String,
      default: '',
    },
    fixedActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      showUserCardModal: 'users/showUserCardModal',
      selectedUserModal: 'users/selectedUserModal',
    }),
    ...mapState('connections', {
      connections: (state) => state.connections,
    }),
    connectionsEnabled() {
      return this.$api.commons.connectionsEnabled();
    },
    shouldShowUserModal: {
      get() {
        return this.showUserCardModal;
      },
      set(newVal) {
        this.$store.commit(`users/${TOGGLE_SHOW_USER_MODAL}`, newVal);
      },
    },
    isEntity() {
      return (
        (this.$auth.loggedIn &&
          this.$auth.user.data.user_option !== USER_OPTION_DEFAULT) ||
        this.user.user_option !== USER_OPTION_DEFAULT
      );
    },
    following() {
      return this.user.following;
    },

    inProgressConnection() {
      return this.currentConnection
        ? [
            CONNECTION_STATUS_PENDING,
            CONNECTION_STATUS_PRECREATED,
            CONNECTION_STATUS_PREPAID,
            CONNECTION_STATUS_PRECHARGED,
            CONNECTION_STATUS_PRESHARED,
          ].includes(this.currentConnection.status)
        : false;
    },
    CONNECTION_STATUS_ACCEPTED() {
      return CONNECTION_STATUS_ACCEPTED;
    },
    CONNECTION_STATUS_PENDING() {
      return CONNECTION_STATUS_PENDING;
    },
    CONNECTION_STATUS_REJECTED() {
      return CONNECTION_STATUS_REJECTED;
    },
    CONNECTION_STATUS_NO_CONNECTION() {
      return CONNECTION_STATUS_NO_CONNECTION;
    },
    currentConnection() {
      return (this.user.connection_status === CONNECTION_STATUS_NO_CONNECTION &&
        !this.connections[this.user.id]) ||
        !this.$auth.loggedIn
        ? undefined
        : this.connections[this.user.id];
    },
    connectionStatus() {
      return this.currentConnection
        ? this.currentConnection.status
        : this.user.connection_status;
    },
    acceptedConnection() {
      return this.currentConnection
        ? this.currentConnection.status === CONNECTION_STATUS_ACCEPTED
        : false;
    },
    type() {
      return CONNECTION;
    },
  },

  methods: {
    ...mapActions({
      feedbackNotificationHandler: 'notifications/feedbackNotificationHandler',
      setLoadingConnection: 'connections/setLoadingConnection',
    }),
    trackBtn() {
      this.shouldShowUserModal = false;
    },
    async handleDisconnect() {
      this.toggleLoading();
      this.feedbackNotificationHandler({
        userId: this.user.id,
        message: this.$t('connections.base.reject'),
        type: this.type,
        status: 'info',
        show: true,
      });
      try {
        const connectionid = `${this.currentConnection.sourceid}-${this.currentConnection.destinationid}`;
        await this.$api.connections.rejectConnection({
          userId: this.user.id,
          sourceid: this.currentConnection.sourceid,
          destinationid: this.currentConnection.destinationid,
          connectionid,
        });
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.reject_success'),
          type: this.type,
          status: 'success',
          show: true,
        });
        this.refresh();
      } catch (error) {
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.errors.reject.feedback'),
          type: this.type,
          status: 'error',
          show: true,
        });
        handleErrors(error);
        this.refresh();
      }
    },
  },
  async mounted() {},
};
