export default {
  not_has_connections: 'No connections to show',
  request_expired: '{type} request expired',
  accept_to_read: 'Accept to read',
  accept_to_receive: 'with {amount}',
  must_accept_to_see_msg: 'You must accept request to see message',
  min_amount: '{minAmount} - Min amount',
  time_accept: 'Time to accept',
  time_to_accept: '{days}d {hours}h {minutes}m',
  not_funds: "You don't have enough funds",
  navbar: {
    messages: 'Messages',
    received: 'Received',
    sent: 'Sent',
  },
  base: {
    btn_label: 'Connect',
    send: 'Sending connection request...',
    send_success: 'Connection request sent ',
    accept: 'Accepting connection request...',
    accept_success: 'Connection established ',
    reject: 'Rejecting connection request',
    reject_success: 'Connection rejected ',
    retry: 'Sending again connection request',
    retry_success: 'Connection request sent ',
    dismiss: 'Dismissing connection request',
    dismiss_success: 'Connection dismissed ',
    refresh: 'Now we will refresh the page to update connections...',
  },
  request: {
    title: 'Send connection request',
    info: 'Total price to connect',
    price: '${total}',
    min_amount: '{amount} - Connect',
    connect: 'Connect',
    fields: {
      text: 'Message',
      amount: 'Payment to {user}',
    },
  },
  request_received: {
    title: '{user} want connect with you.',
    info_sats: 'Accept and receive {amount} in your wallet for connection',
    info: 'Accept connection to be able to comunicate via messages',
  },
  connection_info: {
    user: 'User',
    connection: 'Connection',
    message: 'Message',
    status: 'Status',
    request: 'Request',
    status_list: {
      accepted: 'Accepted',
      pending: 'Pending to accept',
      rejected: 'Not Accepted',
      preshared: 'Request unfinished',
      precreated: 'Request unfinished',
    },
  },
  messages: {
    base: {
      send: 'Sending message...',
      send_success: 'Message sent ',
      accept: 'Accepting message...',
      accept_success: 'Message accepted ',
      reject: 'Rejecting message...',
      reject_success: 'Messages rejected ',
      refresh: 'Updating message...',
      open: 'Open message',
      not_found: 'Not found',
      pending_accept: 'Pending to accept',
      empty: {
        title: 'No conversations to show',
        info: 'Once you are connected with another user you will have to possibility to send and receive messages.',
      },
    },
    status: {
      accepted: '{user} has read the message',
      pending: "{user} still hasn't read the message",
      rejected: "{user} doesn't want to read the message",
    },
    options: {
      volatile: {
        btn: 'Direct',
        label: 'Direct message',
        info: 'Message that will be deleted in time.',
      },
      paid: {
        btn: 'Payment',
        label: 'Add Payment',
        info: 'Message with privacy layer and with a payment added that receiver will earn if accept the message',
      },
      privacy: {
        btn: 'Privacy',
        label: 'Privacy',
        info: 'Message encrypted, persistent and accessed only by you and the receiver when accept it',
      },
    },
    info: {
      paid_message: 'Add money',
      minutes: {
        off: '- Switched OFF the message will be deleted in {expiration} minutes.',
        on: '- Switch ON will add money to the message, it will encrypt and sent, being persistent. Just the receiver can decrypt it and receive the money',
      },
      hours: {
        off: '- Switched OFF the message will be deleted in {expiration} hours.',
        on: '- Switch ON will add money to the message, it will encrypt and sent, being persistent. Just the receiver can decrypt it and receive the money',
      },
      days: {
        off: '- Switched OFF the message will be deleted in {expiration} days.',
        on: '- Switch ON will add money to the message, it will encrypt and sent, being persistent. Just the receiver can decrypt it and receive the money',
      },
      weeks: {
        off: '- Switched OFF the message will be deleted in {expiration} weeks.',
        on: '- Switch ON will add money to the message, it will encrypt and sent, being persistent. Just the receiver can decrypt it and receive the money',
      },
      months: {
        off: '- Switched OFF the message will be deleted in {expiration} months.',
        on: '- Switch ON will add money to the message, it will encrypt and sent, being persistent. Just the receiver can decrypt it and receive the money',
      },
      no_content: {
        no_conversation: 'No conversations to show',
        no_connections: 'No connections to show',
        tip: 'Once you are connected with another user you will have the possibility to send and receive messages',
      },
      news: 'Messages pending to read',
      pendings: 'Messages pending to accept/reject',
      pending: 'Message pending to accept/reject',
    },
    request: {
      connection: '{user} Requested Connection.',
      conditional: '{user} has send you a new message',
    },
    response: {
      connection: '{user} Accepted Connection.',
      reject: {
        privacy: 'You rejected this message',
        paid: 'You rejected this message which would have added {amount} to your wallet.',
      },
    },
    placeholder: {
      new: 'Write message',
      pending: 'Pending that @{user} accept...',
    },
    send: {
      funds: 'Checking cost and funds',
      sending: 'Sending message',
      description: 'Send message from {from} to {to}',
    },
    actions: {
      send: 'Send',
    },
    validations: {
      required: 'Message required',
    },
    connection: {
      info: 'Connection information',
      encrypt_info_sats: `All messages are encrypted, that’s why there’s a small time fetching them.`,
      encrypt_info: `All messages are encrypted, that’s why there’s a small time fetching them.`,
    },
    errors: {
      wallet_propose: 'Error adding payment to message, please try again',
      bitcoin_failure: "Can't connect with the Bitcoin network",
      accept: {
        feedback: 'Error accepting message',
        generic:
          'Error accepting message, please try again. If persist, may contact with support team',
        payment: 'Failed accepting payment. Please, try again later.',
        object: 'Failed creating message. Please, try again later.',
      },
      create: {
        generic: 'Error creating message.',
        object: 'Failed creating message. Please, try again later.',
        payment: 'Failed creating payment. Please, try again later.',
      },
      send: {
        feedback: 'Error sending message',
      },
      reject: {
        feedback: 'Error rejecting message',
        generic: 'Error dismissing message.',
      },
    },
  },
  errors: {
    already_rejected: 'Your previous connection to this user was dismissed',
    send: {
      feedback: 'Error sending connection request.',
    },
    accept: {
      feedback: 'Error accepting connection.',
      generic:
        'Error accepting connection, please try again. If persist, may contact with support team',
      payment:
        'Failed accepting payment. Please, try again later on connections -> receive requests',
      object:
        'Failed creating connection. Please, try again later on connections -> receive requests',
    },
    create: {
      generic:
        'Error creating connection, please try again. If persist, may contact with support team',
      object:
        'Failed creating connection. Please, try again later on connections -> sent requests',
      payment:
        'Failed adding payment. Please, try again later on connections -> sent requests',
    },
    reject: {
      feedback: 'Error rejecting connection.',
    },
    retry: {
      feedback: 'Error sending again connection request.',
    },
    dismiss: {
      feedback: 'Error dismissing connection request.',
    },
  },
};
