import { APP_AUTH_STRATEGY_G2C, SECOND } from '~/vars/api';
import { RESET_STATE_TOKENS, SET_LOGGING_OUT } from '~/vars/store/mutations';
import { EventBus } from '~/components/eventBus/EventBus';
import {
  decryptContentCrypto,
  encryptContentCrypto,
  handleErrors,
} from '~/utils/functions';
export default ({ app, store, $axios }) => ({
  g2cSession: null,
  async getAppHash(type) {
    return new Promise((resolve, reject) => {
      const starter = bsvMnemonic.fromRandom();
      if (typeof type === 'undefined') {
        EventBus.$on('app-hash', async (payload) => {
          const appHash = await this.onReceiveAppHash(payload, starter);
          resolve(appHash);
          EventBus.$off('app-hash');
        });
      }
      this.requestAppHash(starter);
      if (typeof type !== 'undefined') return resolve(starter);
    });
  },
  async requestAppHash(starter) {
    const socketInstance = app.$socket.instance.getConnection();
    const datestamp = new Date().getTime();
    const seed = await encryptContentCrypto(
      app.$auth.user.data.nick + datestamp.toString(16),
      starter.toString(),
    );
    socketInstance.emit('server', {
      type: '12wrequest',
      datestamp,
      payload: {
        nick: app.$auth.user.data.nick,
        seed,
      },
      source: app.$auth.user.data.id,
    });
  },
  async onReceiveAppHash(data, starter) {
    const seed = starter.toHDPrivateKey().privateKey.toHex();
    return await decryptContentCrypto(seed, data.apphash);
  },
  async checkSession(isSafari) {
    await app.$api.g2cSession.checkSession({
      isSafari,
    });
  },
  /**
   * Select login method by words property passed or not
   * @param {Object} data userInfo from login page
   */
  async login(data, loggedForFirstTime = false) {
    try {
      data.words
        ? await this.loginWithWords(data, loggedForFirstTime)
        : await this.loginWithoutWords(data);
    } catch (error) {
      throw new Error(error);
    }
  },
  /**
   * logout
   */
  async logout() {
    try {
      app.store.commit(`session/${SET_LOGGING_OUT}`, true);
      const sessionData = app.$api.g2cSession.getTokens();
      let g2cLogout = null;
      // if no token only $auth.logout
      if (
        !Object.values(sessionData).some(
          (e) => typeof e === 'undefined' || e === null,
        )
      ) {
        sessionData['application'] = app.context.env.g2clibApplication;
        sessionData['nick'] = app.$auth.user.data.nick;
        g2cLogout = await app.$api.g2cSession.logoutUser(sessionData);
        if (g2cLogout.error) {
          throw new Error(g2cLogout.error);
        }
      }
      const params = app.context.route.params;
      if (params.hasOwnProperty('loggedForFirstTime'))
        delete params.loggedForFirstTime;
      this.removeTokens();
      app.store.commit('appNotifications/RESET_STATE');
      if (app.$api.commons.connectionsEnabled())
        app.store.commit('connections/RESET_STATE');
      if (app.$api.commons.messagesEnabled())
        app.store.commit('messages/RESET_STATE');

      app.store.commit('rooms/RESET_STATE');
      app.store.commit('notifications/RESET_STATE');
      app.store.commit('users/RESET_STATE');
      app.store.commit(`tokens/${RESET_STATE_TOKENS}`);
      app.$socket.instance.unsetConnection();
      await app.$auth.logout();
      app.router.push(app.localeRoute({ name: 'index', params }));
    } catch (error) {
      await app.$auth.logout();
      app.router.push(app.localeRoute({ name: 'index' }));
      throw new Error(this.handleError(error));
    }
    setTimeout(() => {
      app.store.commit(`session/${SET_LOGGING_OUT}`, false);
    }, 1 * SECOND);
  },

  /**
   * Full login with security key words
   * OBJECT
   * @param {String} words 12 words security key
   * @param {String} password account password
   * @param {String} nick userName
   */
  async loginWithWords({ words, password, nick, code }, loggedForFirstTime) {
    const application = app.context.env.g2clibApplication;
    console.log({ words, password, nick, application });
    // Nuxt-auth login with g2c_user strategy
    try {
      if (!this.g2cSession) {
        this.g2cSession = await app.$api.g2cSession
          .login(words, application, nick)
          .catch((error) => {
            if (error.includes('logged in')) {
              return 'logged_in';
            } else throw new Error(error);
          }); // G2CLIB TOKENS
        if (this.g2cSession === 'logged_in') {
          this.g2cSession = await app.$api.g2cSession.login(
            words,
            application,
            nick,
            true,
          );
        }
      }
      app.$auth
        .loginWith(APP_AUTH_STRATEGY_G2C, {
          data: { nick, password },
        })
        .then(async () => {
          setTimeout(async () => {
            await this.onUserLoggedInG2c(
              this.g2cSession,
              words,
              loggedForFirstTime,
              code,
            );
          }, 5000);
        })
        .catch((error) => {
          throw new Error(this.handleError(error));
        });
    } catch (error) {
      throw new Error(this.handleError(error));
    }
  },
  async checkWords(words) {
    try {
      const appHash = await this.getAppHash();
      const fileWords = await this.generateTxt(appHash, words);
      return fileWords;
    } catch (error) {
      throw error;
    }
  },
  async generateTxt(appHash, words) {
    try {
      const backupContent = await app.$api.g2cSession.g2cWordsBackup(
        appHash,
        words.trim(),
      );

      return backupContent;
    } catch (error) {
      console.log('ERROR GENERATING BACKUP', error);
      throw error;
    }
  },
  async saveBackUpFile1(chunk) {
    try {
      return await $axios
        .post('auth/save-backup-file-1', {
          chunk,
        })
        .then(({ data }) => data);
    } catch (error) {
      console.log('ERROR SENDING BACKUP 1', error);
      throw error;
    }
  },
  async saveBackUpFile2({ chunk, code }) {
    try {
      const instance = $axios.create({
        baseURL: `${process.env.custodialBaseURL}`,
        headers: { 'x-authorization': app.context.env.custodialApiKey },
      });
      return await instance
        .post(`/api/auth/new-session`, {
          code_number: code,
          mobile_number: app.$auth.user.data.mobile,
          chunk,
          application: app.context.env.custodialApplication,
        })
        .then(({ data }) => data);
    } catch (error) {
      console.log('ERROR SENDING BACKUP 2', error);
      throw error;
    }
  },
  /**
   * Login with logindata
   * OBJECT
   * @param {String} password account password
   * @param {String} nick userName
   * @param {String} loginData loginData
   */
  async loginWithoutWords({ nick, password, code }, loginData) {
    try {
      const application = app.context.env.g2clibApplication;

      const response = await app.$auth.loginWith(APP_AUTH_STRATEGY_G2C, {
        data: { nick, password },
      });
      app.$socket.instance.setConnection();
      EventBus.$on('socket-instance-setted', async () => {
        const starter = await this.getAppHash('login');
        EventBus.$on('app-hash', async (payload) => {
          try {
            const appHash = await this.onReceiveAppHash(payload, starter);
            const restoredwords = await app.$api.g2cSession.g2cWordsRestore(
              appHash,
              loginData,
            );
            // const restoredwords = {
            //   words:
            //     'outdoor spray wasp leaf cable fat live swallow patch erase fork mean',
            // };
            const words = restoredwords.words.trim();
            if (!this.g2cSession) {
              this.g2cSession = await app.$api.g2cSession
                .login(words, application, nick)
                .catch((error) => {
                  console.log(error);
                  if (error.includes('logged in')) {
                    return 'logged_in';
                  } else throw new Error(error);
                }); // G2CLIB TOKENS
              if (this.g2cSession === 'logged_in') {
                this.g2cSession = await app.$api.g2cSession.login(
                  words,
                  application,
                  nick,
                  true,
                );
              }
              EventBus.$off('socket-instance-setted');
              EventBus.$off('app-hash');
              await this.onUserLoggedInG2c(this.g2cSession, words, false, code);
            }
          } catch (error) {
            console.log('Error login without words');
            console.log(error);
            if (app.$auth.loggedIn) this.logout();
            throw new Error(this.handleError(error));
          }
        });
      });
      return true;
    } catch (error) {
      console.log('Error login without words external trycatch');
      console.log(error);
      if (app.$auth.loggedIn) this.logout();
      throw new Error(this.handleError(error));
    }
  },
  // /**
  //  * Privacy data management login without security key words
  //  * OBJECT
  //  * @param {String} password account password
  //  * @param {String} nick userName
  //  */
  // async loginWithoutWords({ nick, password }, loginData) {
  //   // Nuxt-auth login with user strategy
  //   return await app.$auth
  //     .loginWith(APP_AUTH_STRATEGY_WITHOUT_WORDS, {
  //       data: {
  //         nick,
  //         password,
  //       },
  //     })
  //     .then(async (response) => {
  //       return response.data;
  //       // app.router.push(app.localePath({ name: 'account-settings' }));
  //     })
  //     .catch((error) => {
  //       throw new Error(this.handleError(error));
  //     });
  // },

  /**
   * Standar login (prelogin)
   * OBJECT
   * @param {String} password account password
   * @param {String} nick userName
   */
  async loginStandard({ nick, password, login }) {
    try {
      return await $axios
        .post('auth/login-standard', {
          nick,
          password,
          login,
        })
        .then(({ data }) => data);
    } catch (error) {
      console.log('ERROR LOGIN STANDARD', error);
      throw error;
    }
  },
  /**
   * Manage full logged user browser info and go home page
   * OBJECT
   * @param {String} tokenid token from g2clib access
   * @param {String} tokens1 token from g2clib access
   * @param {String} tokenc1 token from g2clib access
   */
  async onUserLoggedInG2c(g2cSession, words, loggedForFirstTime, code) {
    if (
      app.$auth.$storage.getLocalStorage('last-user') !== app.$auth.user.data.id
    ) {
      app.$auth.$storage.setCookie('notstatus', 0);
      app.$auth.$storage.setLocalStorage('last-user', app.$auth.user.data.id);
    }
    console.log({ g2cSession });
    app.$api.g2cSession.setToken(g2cSession);
    this.g2cSession = null;

    /*
    const roomPromises = [];
    roomPromises.push(await app.$api.meetings.getPrivateRooms());
    roomPromises.push(await app.$api.meetings.getPublicRooms());
    Promise.allSettled(roomPromises).then((results) => {
      const privateRooms = results[0].value;
      const publicRooms = results[1].value;
      store.dispatch('rooms/setRooms', {
        privateRooms,
        publicRooms,
      });
    });
    */
    app.$socket.instance.setConnection();
    EventBus.$on('socket-instance-setted', async () => {
      const starter = await this.getAppHash('login');
      EventBus.$on('app-hash', async (payload) => {
        try {
          const appHash = await this.onReceiveAppHash(payload, starter);
          const fileWords = await app.$api.g2cSession.g2cWordsBackup(
            appHash,
            words.trim(),
          );
          await this.saveBackupFiles(fileWords, appHash, words, code);

          EventBus.$off('app-hash');
          EventBus.$off('socket-instance-setted');
        } catch (error) {
          EventBus.$off('app-hash');
          EventBus.$off('socket-instance-setted');
          console.log(error);
        }
        EventBus.$emit('logged-in', true);
      });
    });
    if (loggedForFirstTime) {
      EventBus.$on('logged-in', async (value) => {
        if (value === true) {
          let routeTo = null;
          if (app.context.from && !app.context.from.path.includes('session')) {
            app.context.from.params.loggedForFirstTime = true;
            routeTo = app.context.from;
          } else {
            routeTo = app.localeRoute({
              name: 'index',
              params: { loggedForFirstTime: true },
            });
          }
          EventBus.$off('logged-in');
          app.store.dispatch('users/setUserToken');
          await this.setUserFolders();
          return app.router.push(routeTo);
        }
      });
    }
    if (app.$api.commons.connectionsEnabled())
      Promise.all([await app.$api.user.getUserConnections()]).then(
        (results) => {
          console.log(results);
          store.dispatch('connections/setConnections', results[0]);
        },
      );
  },

  async setUserFolders() {
    try {
      if (!app.$api.commons.userPaidFees()) {
        if (app.$api.commons.connectionsEnabled())
          await app.$api.connections.createConnectionsFolder();
        await app.$api.market.createTokenFolder();
      }
      return true;
    } catch (error) {
      handleErrors(error);
      return false;
    }
  },

  async saveBackupFiles(fileWords, appHash, words, code) {
    return new Promise(async (resolve, reject) => {
      let success = false;
      let tries = 0;
      let result1;
      let result2;
      do {
        if (typeof result1 === 'undefined') {
          result1 = await this.saveBackUpFile2({
            chunk: fileWords[1],
            code,
          }).catch((error) => {
            console.log(error);
            return { error: true };
          });
        }
        if (typeof result1.error !== 'undefined' && result1.error === true)
          result1 = undefined;
        else if (typeof result2 === 'undefined') {
          // primero movil y si esta ok entonces mail, si no no da ni error porque login ha podido hacerlo.
          // en el caso de que uno de los dos falle solo intentar el otro?
          result2 = await this.saveBackUpFile1(fileWords[0]).catch((error) => {
            console.log(error);
            return { error: true };
          });
          if (typeof result2.error !== 'undefined' && result2.error === true)
            result2 = undefined;
        }
        if (typeof result1 !== 'undefined' && typeof result2 !== 'undefined') {
          const restoredwords = await app.$api.g2cSession.g2cWordsRestore(
            appHash,
            [result1.data.chunk, result2.data.chunk],
          );
          success = restoredwords.words.trim() === words;
        }
        tries++;
      } while (success !== true && tries < 10);
      if (success) resolve(true);
      else reject('Error storing chunks');
    });
  },

  /**
   * Get all market buy auction orders
   * @param {Object} {}
   * @returns
   */
  async getMarketTransactions() {
    return new Promise(async (resolve, reject) => {
      $axios
        .get(`/order/retrievemarket`)
        .then(({ data }) => {
          if (!data || data.error) {
            reject(
              data.message ? data.message : 'Error get market transactions',
            );
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Handle login errors
   * @param {Object} error
   * @returns
   */
  handleError(error) {
    let errorMessage = error.message;
    if (error.response) {
      let text = 'message';
      if (error.response.status === 401) {
        text = 'app';
      }
      errorMessage = app.i18n.t(`forms.error.${text}`);
    }
    return errorMessage || error;
  },
  removeTokens() {
    app.$auth.$storage.removeLocalStorage('g2ctoken');
    app.$auth.$storage.removeLocalStorage('tokenid');
    app.$auth.$storage.removeLocalStorage('tokens1');
    app.$auth.$storage.removeLocalStorage('tokenc1');
    app.$auth.$storage.removeCookie('notstatus');
  },
});
