import Vue from 'vue';
import jwt_decode from 'jwt-decode';
import {
  RESET_STATE_USER,
  TOGGLE_REQUESTED_ALL_USERS,
  ADD_USER_REQUEST_USER,
  REMOVE_USER_FROM_REQUESTED_USERS,
  ADD_USER,
  UPDATE_USER,
  ADD_USERS,
  SET_ONLINE_USERS,
  SET_SELECTED_USER_CONNECT_MODAL,
  TOGGLE_SHOW_USER_MODAL,
  SET_SELECTED_USER_MODAL,
  SET_OPTIONS_USER_MODAL,
  ADD_USER_NICK_REQUEST_USER,
  SET_TOKEN,
  TOGGLE_REQUESTED_ALL_COMPANIES,
  TOGGLE_ALL_USERS_ARE_LOADED,
  TOGGLE_ALL_COMPANIES_ARE_LOADED,
  TOGGLE_SHOW_USER_CONNECT_MODAL,
  SET_SELLERS,
  SET_REQUESTED_ALL_SELLERS,
} from '../vars/store/mutations';

import {
  addUsers,
  addUser,
  updateUser,
  showUserModal,
  showUserConnectModal,
  setUserToken,
  hiddeUserModal,
  addCompanies,
  setSellers,
} from '../vars/store/actions';
import { USER_OPTION_COMPANY, USER_OPTION_DEFAULT } from '~/vars/api';

const getDefaultState = () => ({
  users: [],
  onlineUsers: [],
  sellers: [],
  requestedSellers: false,
  requestedUsersById: [],
  requestedUsersByNick: [],
  requestedAllUsers: false,
  requestedAllCompanies: false,
  showUserCardModal: false,
  selectedUserModal: {},
  optionsUserModal: { showDisconnectBtn: false },
  allUsersAreLoaded: false,
  allCompaniesAreLoaded: false,
  showUserConnectModal: false,
  selectedUserConnectModal: {},
  userToken: {},
  lastUpdate: null,
});

export const state = getDefaultState();

export const getters = {
  getUser: (state) => (userBy) =>
    state.users.find(({ id, userId, nick }) =>
      [id, userId, nick].includes(userBy),
    ),
  getAllUsers: (state) => state.users,
  getAllSellers: (state) =>
    state.users.filter((user) =>
      state.sellers.some((seller) => seller === user.id),
    ),
  getUsers: (state) =>
    state.users.filter((user) => user.user_option === USER_OPTION_DEFAULT),
  getCompanies: (state) =>
    state.users.filter((user) => user.user_option === USER_OPTION_COMPANY),
  userAreOnline: (state) => (userId) => {
    return state.onlineUsers.includes(userId);
  },
  userIsSaved: (state) => (userId) => {
    return state.users.some(({ id }) => id === userId);
  },
  getAllUsersAreLoaded: (state) => state.allUsersAreLoaded,
  allUsersAreRequested: (state) => state.requestedAllUsers,
  sellersAreRequested: (state) => state.requestedSellers,
  getCompaniesAreLoaded: (state) => state.allCompaniesAreLoaded,
  allCompaniesAreRequested: (state) => state.requestedAllCompanies,
  userAreRequested: (state) => (userBy) => {
    return (
      state.requestedUsersById.includes(userBy) ||
      state.requestedUsersByNick.includes(userBy)
    );
  },
  haveUsers: (state) => Boolean(state.users.length),
  showUserCardModal: (state) => state.showUserCardModal,
  selectedUserModal: (state) => state.selectedUserModal,
  optionsUserModal: (state) => state.optionsUserModal,
  selectedUserConnectModal: (state) => state.selectedUserConnectModal,
  showUserConnectModal: (state) => state.showUserConnectModal,
  getTokenUser: (state) => state.userToken,
  getLastUpdate: (state) => (state.lastUpdate ? state.lastUpdate : 0),
};

export const actions = {
  [addUsers]({ commit, getters }, users) {
    commit(ADD_USERS, users);
    commit(TOGGLE_REQUESTED_ALL_USERS, false);
    commit(TOGGLE_ALL_USERS_ARE_LOADED, true);
  },
  [addCompanies]({ commit, getters }, users) {
    commit(ADD_USERS, users);
    commit(TOGGLE_REQUESTED_ALL_COMPANIES, false);
    commit(TOGGLE_ALL_COMPANIES_ARE_LOADED, true);
  },
  [addUser]({ commit, getters }, user) {
    commit(ADD_USER, user);
    commit(REMOVE_USER_FROM_REQUESTED_USERS, user.id);
  },
  [updateUser]({ commit, getters }, { userid, ...userInfo }) {
    if (getters.userIsSaved(userid)) commit(UPDATE_USER, { userid, userInfo });
  },
  [showUserModal]({ commit }, { user, options }) {
    commit(SET_SELECTED_USER_MODAL, user);
    commit(TOGGLE_SHOW_USER_MODAL, true);
    commit(SET_OPTIONS_USER_MODAL, options);
  },
  [hiddeUserModal]({ commit }) {
    commit(SET_SELECTED_USER_MODAL, null);
    commit(TOGGLE_SHOW_USER_MODAL, false);
  },
  [showUserConnectModal]({ commit }, user) {
    commit('SET_SELECTED_USER_CONNECT_MODAL', user);
    commit('TOGGLE_SHOW_USER_CONNECT_MODAL', true);
  },
  [setUserToken]({ commit }) {
    if (this.$auth.loggedIn) {
      const token = this.$auth.getToken('g2c_user').split('Bearer ')[1];
      const tokenDecoded = jwt_decode(token);
      commit(SET_TOKEN, tokenDecoded);
    }
  },
  [setSellers]({ commit }, users) {
    commit(SET_SELLERS, users);
  },
};

export const mutations = {
  [TOGGLE_ALL_USERS_ARE_LOADED](state, loaded) {
    state.allUsersAreLoaded = loaded;
  },
  [TOGGLE_ALL_COMPANIES_ARE_LOADED](state, loaded) {
    state.allCompaniesAreLoaded = loaded;
  },
  [TOGGLE_SHOW_USER_CONNECT_MODAL](state, show) {
    state.showUserConnectModal = show;
  },
  [SET_SELECTED_USER_CONNECT_MODAL](state, user) {
    state.selectedUserConnectModal = user;
  },
  [TOGGLE_SHOW_USER_MODAL](state, show) {
    state.showUserCardModal = show;
  },
  [SET_SELECTED_USER_MODAL](state, user) {
    state.selectedUserModal = user;
  },
  [SET_OPTIONS_USER_MODAL](state, options) {
    state.optionsUserModal = { showDisconnectBtn: false };
    if (options) state.optionsUserModal = Object.assign({}, options);
  },
  [RESET_STATE_USER](state) {
    Object.assign(state, getDefaultState());
  },
  [TOGGLE_REQUESTED_ALL_USERS](state, status) {
    state.requestedAllUsers = status;
  },
  [TOGGLE_REQUESTED_ALL_USERS](state, status) {
    state.requestedAllUsers = status;
  },
  [TOGGLE_REQUESTED_ALL_COMPANIES](state, status) {
    state.requestedAllCompanies = status;
  },
  [ADD_USER_REQUEST_USER](state, userId) {
    state.requestedUsersById.push(userId);
  },
  [ADD_USER_NICK_REQUEST_USER](state, userNick) {
    state.requestedUsersByNick.push(userNick);
  },
  [REMOVE_USER_FROM_REQUESTED_USERS](state, userId) {
    const requestedUsers = state.requestedUsersById.filter(
      (id) => id !== userId,
    );
    state.requestedUsersById = requestedUsers;
  },
  [ADD_USER](state, user) {
    const users = [...state.users];
    users.push(user);
    state.users = users;
  },
  [UPDATE_USER](state, { userid, userInfo }) {
    let userIndex;
    const user = state.users.find((user, index) => {
      if (user.id === userid) {
        userIndex = index;
        return user;
      }
    });
    const updatedUser = Object.assign(user, userInfo);
    Vue.set(state.users, userIndex, updatedUser);
  },
  [ADD_USERS](state, users) {
    const allUsers = [
      ...state.users.filter(
        (user) => !users.some((newUser) => newUser.id === user.id),
      ),
      ...users,
    ];
    state.users = allUsers.sort(
      (a, b) => b.followers_counter - a.followers_counter,
    );
    state.lastUpdate = new Date().getTime();
  },
  [SET_ONLINE_USERS](state, users) {
    state.onlineUsers = users;
  },
  [SET_TOKEN](state, token) {
    state.userToken = token;
  },
  [SET_SELLERS](state, users) {
    state.sellers = [...users];
  },
  [SET_REQUESTED_ALL_SELLERS](state, requested) {
    state.requestedSellers = requested;
  },
};
