import { render, staticRenderFns } from "./ConfirmLogoutModal.vue?vue&type=template&id=5a351363&scoped=true&"
import script from "./ConfirmLogoutModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmLogoutModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmLogoutModal.vue?vue&type=style&index=0&id=5a351363&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a351363",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/tmp/build_51aa5b7f/components/base/BaseBtn.vue').default})
