
import { PROJECT_NAME } from '~/vars/commons';
import { mapGetters, mapState } from 'vuex';
import { SIDEBAR_WIDTH_CLOSED, SIDEBAR_WIDTH_OPEN } from '~/vars/layout';
import { setListingOffering, showSessionModal } from '~/vars/store/actions';
import {
  SHOW_CONFIRM_LISTING_MODAL,
  SHOW_G2C_IN_PROCESS_MODAL,
} from '~/vars/store/mutations';

import mpDB from '~/indexedDB/mpDB';
export default {
  components: {
    UserCardModal: () => import('~/components/user/modals/UserCardModal.vue'),
    UserConnectModal: () =>
      import('~/components/user/modals/UserConnectModal.vue'),
    UserConnectNoSatsModal: () =>
      import('~/components/user/modals/UserConnectNoSatsModal.vue'),
    LayoutLogin: () => import('~/components/layout/LayoutLogin.vue'),
    VerifyEntityModal: () =>
      import('~/components/session/VerifyEntityModal.vue'),
    HandleG2cObjectsModal: () =>
      import('~/components/market/modals/HandleG2cObjectsModal.vue'),
    HandleCollectionsModal: () =>
      import('~/components/market/modals/HandleCollectionsModal.vue'),
    HandleOwnerCollectionsModal: () =>
      import('~/components/market/modals/HandleOwnerCollectionsModal.vue'),
    TransferProductModal: () =>
      import('~/components/market/modals/TransferProductModal.vue'),
    HandleInvitationOfferingModal: () =>
      import('~/components/market/modals/HandleInvitationOfferingModal.vue'),
    DelistOfferingModal: () =>
      import('~/components/market/modals/DelistOfferingModal.vue'),
    HandleAddressModal: () =>
      import('~/components/market/modals/HandleAddressModal.vue'),
    BaseReportModal: () => import('~/components/base/BaseReportModal.vue'),
    BaseMailingInvitationModal: () =>
      import('~/components/base/BaseMailingInvitationModal.vue'),
    RedeemProductModal: () =>
      import('~/components/market/modals/RedeemProductModal.vue'),
    LoginModal: () => import('~/components/modals/LoginModal.vue'),
    HandleRoyaltyModal: () =>
      import('~/components/market/modals/HandleRoyaltyModal.vue'),
    HandleBeneficiaryModal: () =>
      import('~/components/market/modals/HandleBeneficiaryModal.vue'),
    CreateQrModal: () => import('~/components/market/modals/CreateQrModal.vue'),
    HandleTicketOptionsModal: () =>
      import('~/components/market/modals/HandleTicketOptionsModal.vue'),
    offlineWarning: () => import('~/components/commons/OfflineWarning.vue'),
    MintersModal: () => import('~/components/minters/MintersModal.vue'),
    VerifyModal: () => import('~/components/verification/VerifyModal.vue'),
    BaseImgPreview: () => import('~/components/base/BaseImgPreview.vue'),
    ConfirmAlertModal: () =>
      import('~/components/account/wallet/ConfirmAlertModal.vue'),
    G2cAlertModal: () => import('~/components/modals/G2cAlertModal.vue'),
  },
  middleware: ['marketplace_middleware', 'g2c_middleware'],
  data: () => ({
    showEditProfileModal: false,
    layoutSidebarAreMounted: false,
    showHsChat: false,
    loginIn: false,
  }),
  computed: {
    ...mapState('connections', {
      connections: (state) => state.connections,
    }),
    ...mapGetters({
      showUserCardModal: 'users/showUserCardModal',
      showUserConnectModal: 'users/showUserConnectModal',
      showTransferProductModal: 'market/showTransferProductModal',
      showHandleInvitationOfferingModal:
        'market/showHandleInvitationOfferingModal',
      showDelistOfferingModal: 'market/showDelistOfferingModal',
      showHandleAddressModal: 'market/showHandleAddressModal',
      showRedeemProductModal: 'market/showRedeemProductModal',
      showCreateQrModal: 'market/showCreateQrModal',
      showTicketOptionsModal: 'market/showTicketOptionsModal',
      showReportModal: 'report/showReportModal',
      showMailingInvitationModal: 'mailing/showMailingInvitationModal',
      showSessionModal: 'session/showSessionModal',
      showVerifyEntityModal: 'session/showVerifyEntityModal',
      showHandleG2cObjectModal: 'market/showHandleG2cObjectModal',
      showHandleCollectionsModal: 'market/showHandleCollectionsModal',
      showHandleOwnerCollectionsModal: 'market/showHandleOwnerCollectionsModal',
      showHandleRoyaltyModal: 'market/showHandleRoyaltyModal',
      showHandleBeneficiaryModal: 'market/showHandleBeneficiaryModal',
      sidebarAreOpen: 'ui/areSidebarOpened',
      showMinterModal: 'ui/showMinterModal',
      showVerifyModal: 'ui/showVerifyModal',
      previewImgs: 'ui/previewImgs',
      previewOptions: 'ui/previewOptions',
      showPreviewModal: 'ui/showPreviewModal',
      showConfirmListingModal: 'market/showConfirmListingModal',
      offeringToList: 'market/offeringToList',
      g2cInProcess: 'processing/g2cInProcess',
      showG2cInProcessModal: 'processing/showG2cInProcessModal',
      g2cAlert: 'processing/g2cAlert',
    }),
    offeringToListById() {
      return this.offeringToList(this.showConfirmListingModal.stampId);
    },
    containerStyle() {
      if (this.$vuetify.breakpoint.mobile) {
        return '';
      }
      return {
        width: '100%',
        paddingLeft: this.sidebarAreOpen
          ? SIDEBAR_WIDTH_OPEN
          : SIDEBAR_WIDTH_CLOSED,
      };
    },
    userPaidFees() {
      return this.$api.commons.userPaidFees();
    },
  },
  watch: {
    '$auth.loggedIn'(loggedIn) {
      if (loggedIn) {
        this.$socket.instance.setConnection();
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          'identify',
          {
            email: this.$auth.user.data.email,
            marketplace_id: this.$auth.user.data.id,
            registered: true,
            verified: this.$auth.user.data.is_verified,
            validated: this.$auth.user.data.is_validated,
          },
        ]);
        _hsq.push(['trackPageView']);
      } else {
        document.title = PROJECT_NAME;
        location.reload();
      }
    },
    '$auth.user.data.is_verified'(verified) {
      if (verified) {
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          'identify',
          {
            user_status: 'verified',
            verified: true,
          },
        ]);
        _hsq.push(['trackPageView']);
      }
    },
    '$auth.user.data.is_validated'(validated) {
      const _hsq = (window._hsq = window._hsq || []);
      if (validated) {
        _hsq.push([
          'identify',
          {
            user_status: 'validated',
            validated: validated,
          },
        ]);
      } else {
        _hsq.push([
          'identify',
          {
            validated: validated,
          },
        ]);
      }
      _hsq.push(['trackPageView']);
    },
    g2cInProcess(value) {
      if (value === true) {
        window.addEventListener('beforeunload', this.preventNavigation);
      } else {
        window.removeEventListener('beforeunload', this.preventNavigation);
      }
    },
  },
  async mounted() {
    const rejectBtn = document.querySelector(
      "[data-cookiefirst-action='reject']",
    );
    if (rejectBtn) {
      rejectBtn.parentElement.style.display = 'none';

      const adjustBtn = document.querySelector(
        "[data-cookiefirst-action='adjust']",
      );
      adjustBtn.addEventListener('click', (e) => {
        const dialog = document.querySelector('dialog');
        if (
          dialog.attributes['aria-labelledby'].nodeValue ===
          'cookie-preference-panel-title'
        ) {
          const denyBtn = dialog.querySelector(
            "[data-cookiefirst-action='reject']",
          );
          denyBtn.parentElement.style.display = 'none';

          const saveBtn = dialog.querySelector(
            "[data-cookiefirst-action='save']",
          );
          saveBtn.parentElement.style.flex = '0 0 100%';
          saveBtn.parentElement.style.maxWidth = '100%';
        }
      });
      adjustBtn.parentElement.style.flex = '0 0 100%';
      adjustBtn.parentElement.style.maxWidth = '100%';
    }
    this.$socket.instance.setConnection();
    if (this.$auth.loggedIn && this.$api.commons.connectionsEnabled()) {
      console.log({ mpDB });
      await mpDB.connect(this.$auth.user.data.id);
      if (
        Object.values(this.connections).length === 0 &&
        Object.values(this.$auth.user.data.connections).length !== 0
      ) {
        this.$store.dispatch(
          'connections/setConnections',
          this.$auth.user.data.connections,
        );
      }
    }

    if (this.$route.params.loggedForFirstTime) {
      this.showEditProfileModal = true;
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          user_status: 'registered',
          registered: true,
          marketplace_id: this.$auth.user.data.id,
        },
      ]);
      _hsq.push(['trackPageView']);
    }
  },
  methods: {
    preventNavigation(event) {
      // need user interaction with page to fire this event propertly
      event.preventDefault();
      event.returnValue = this.$t('base.g2c_in_process.warning');
      return event.returnValue;
    },
    async handleConfirmListingModal(value) {
      if (value) {
        this.$api.market.handleCreateOffering({
          stampId: this.showConfirmListingModal.stampId,
        });
      } else {
        await this.$api.market.cancelCreateOffering({
          stampId: this.showConfirmListingModal.stampId,
        });
        this.$store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
        this.$store.dispatch(`market/${setListingOffering}`, {
          stampId: this.showConfirmListingModal.stampId,
          remove: true,
        });
      }
      this.$store.commit(`market/${SHOW_CONFIRM_LISTING_MODAL}`, {
        stampId: null,
        show: false,
      });
    },
    closeG2cInProcessModal() {
      this.$store.commit(`processing/${SHOW_G2C_IN_PROCESS_MODAL}`, false);
    },
  },
};
