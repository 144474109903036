import { render, staticRenderFns } from "./UserFullCard.vue?vue&type=template&id=4c6b0f04&"
import script from "./UserFullCard.vue?vue&type=script&lang=js&"
export * from "./UserFullCard.vue?vue&type=script&lang=js&"
import style0 from "./UserFullCard.vue?vue&type=style&index=0&id=4c6b0f04&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/tmp/build_51aa5b7f/components/user/UserAvatar.vue').default,UserCardsAvatar: require('/tmp/build_51aa5b7f/components/user/UserCardsAvatar.vue').default,UserFollowers: require('/tmp/build_51aa5b7f/components/user/cards/components/UserFollowers.vue').default,UserConnections: require('/tmp/build_51aa5b7f/components/user/cards/components/UserConnections.vue').default,BaseIcon: require('/tmp/build_51aa5b7f/components/base/BaseIcon.vue').default,SsrCarousel: require('/tmp/build_51aa5b7f/node_modules/vue-ssr-carousel/index.js').default,UserCardBtns: require('/tmp/build_51aa5b7f/components/user/cards/btns/UserCardBtns.vue').default,BaseBtn: require('/tmp/build_51aa5b7f/components/base/BaseBtn.vue').default})
