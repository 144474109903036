import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _459a7765 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _425b09a9 = () => interopDefault(import('../pages/account/activity.vue' /* webpackChunkName: "pages/account/activity" */))
const _2f7967b8 = () => interopDefault(import('../pages/account/activity/_slug.vue' /* webpackChunkName: "pages/account/activity/_slug" */))
const _b31f7802 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _011f28bd = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _1004e516 = () => interopDefault(import('../pages/account/settings/general.vue' /* webpackChunkName: "pages/account/settings/general" */))
const _368c3ad6 = () => interopDefault(import('../pages/account/settings/paymentmethod.vue' /* webpackChunkName: "pages/account/settings/paymentmethod" */))
const _e06c43da = () => interopDefault(import('../pages/account/wallet.vue' /* webpackChunkName: "pages/account/wallet" */))
const _71b6fb29 = () => interopDefault(import('../pages/account/wallet/mymints.vue' /* webpackChunkName: "pages/account/wallet/mymints" */))
const _d270fb1c = () => interopDefault(import('../pages/account/wallet/mytokens.vue' /* webpackChunkName: "pages/account/wallet/mytokens" */))
const _2142b412 = () => interopDefault(import('../pages/account/wallet/mytokenshistory.vue' /* webpackChunkName: "pages/account/wallet/mytokenshistory" */))
const _8c5bbc80 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _38ab8e78 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _9d4ef722 = () => interopDefault(import('../pages/messages/_id.vue' /* webpackChunkName: "pages/messages/_id" */))
const _e2e6fdd8 = () => interopDefault(import('../pages/redeem.vue' /* webpackChunkName: "pages/redeem" */))
const _7510cb3e = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _5d67e28c = () => interopDefault(import('../pages/sellers.vue' /* webpackChunkName: "pages/sellers" */))
const _b8bc67f2 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _a0929116 = () => interopDefault(import('../pages/kyc/pending.vue' /* webpackChunkName: "pages/kyc/pending" */))
const _f0eae438 = () => interopDefault(import('../pages/kyc/redirecting.vue' /* webpackChunkName: "pages/kyc/redirecting" */))
const _ff17842e = () => interopDefault(import('../pages/legal/terms.vue' /* webpackChunkName: "pages/legal/terms" */))
const _25b64bed = () => interopDefault(import('../pages/market/preview.vue' /* webpackChunkName: "pages/market/preview" */))
const _4d09f91a = () => interopDefault(import('../pages/market/preview/collections.vue' /* webpackChunkName: "pages/market/preview/collections" */))
const _4e0ed0b5 = () => interopDefault(import('../pages/market/preview/favorites.vue' /* webpackChunkName: "pages/market/preview/favorites" */))
const _8ec5c5ae = () => interopDefault(import('../pages/market/preview/marketplace.vue' /* webpackChunkName: "pages/market/preview/marketplace" */))
const _7f3c0a58 = () => interopDefault(import('../pages/market/preview/mybids.vue' /* webpackChunkName: "pages/market/preview/mybids" */))
const _3fa66642 = () => interopDefault(import('../pages/market/preview/myofferings.vue' /* webpackChunkName: "pages/market/preview/myofferings" */))
const _2d0157c8 = () => interopDefault(import('../pages/market/preview/assets/related/_involved.vue' /* webpackChunkName: "pages/market/preview/assets/related/_involved" */))
const _175a3143 = () => interopDefault(import('../pages/market/preview/assets/_collection.vue' /* webpackChunkName: "pages/market/preview/assets/_collection" */))
const _7358b55a = () => interopDefault(import('../pages/my-tokens/mint-form.vue' /* webpackChunkName: "pages/my-tokens/mint-form" */))
const _9fefefae = () => interopDefault(import('../pages/resources/image.vue' /* webpackChunkName: "pages/resources/image" */))
const _0894a434 = () => interopDefault(import('../pages/session/forgot.vue' /* webpackChunkName: "pages/session/forgot" */))
const _4739eb30 = () => interopDefault(import('../pages/session/login.vue' /* webpackChunkName: "pages/session/login" */))
const _074fa0ae = () => interopDefault(import('../pages/session/signup.vue' /* webpackChunkName: "pages/session/signup" */))
const _3c32728e = () => interopDefault(import('../pages/stripe/onboarding/minter.vue' /* webpackChunkName: "pages/stripe/onboarding/minter" */))
const _ed55fc46 = () => interopDefault(import('../pages/stripe/payment/cancel.vue' /* webpackChunkName: "pages/stripe/payment/cancel" */))
const _a22fa8f0 = () => interopDefault(import('../pages/stripe/payment/refresh.vue' /* webpackChunkName: "pages/stripe/payment/refresh" */))
const _93fff6a0 = () => interopDefault(import('../pages/stripe/payment/success.vue' /* webpackChunkName: "pages/stripe/payment/success" */))
const _ebbc3a98 = () => interopDefault(import('../pages/market/collection/_id.vue' /* webpackChunkName: "pages/market/collection/_id" */))
const _2c1d0882 = () => interopDefault(import('../pages/market/product/_id/index.vue' /* webpackChunkName: "pages/market/product/_id/index" */))
const _fc2b727c = () => interopDefault(import('../pages/my-tokens/list-form/_id.vue' /* webpackChunkName: "pages/my-tokens/list-form/_id" */))
const _50f13166 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _65f1a074 = () => interopDefault(import('../pages/market/product/_id/checkout.vue' /* webpackChunkName: "pages/market/product/_id/checkout" */))
const _760c038a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _10c28c45 = () => interopDefault(import('../pages/_usernick.vue' /* webpackChunkName: "pages/_usernick" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _459a7765,
    name: "account___en",
    children: [{
      path: "activity",
      component: _425b09a9,
      name: "account-activity___en",
      children: [{
        path: ":slug?",
        component: _2f7967b8,
        name: "account-activity-slug___en"
      }]
    }, {
      path: "profile",
      component: _b31f7802,
      name: "account-profile___en"
    }, {
      path: "settings",
      component: _011f28bd,
      name: "account-settings___en",
      children: [{
        path: "general",
        component: _1004e516,
        name: "account-settings-general___en"
      }, {
        path: "paymentmethod",
        component: _368c3ad6,
        name: "account-settings-paymentmethod___en"
      }]
    }, {
      path: "wallet",
      component: _e06c43da,
      name: "account-wallet___en",
      children: [{
        path: "mymints",
        component: _71b6fb29,
        name: "account-wallet-mymints___en"
      }, {
        path: "mytokens",
        component: _d270fb1c,
        name: "account-wallet-mytokens___en"
      }, {
        path: "mytokenshistory",
        component: _2142b412,
        name: "account-wallet-mytokenshistory___en"
      }]
    }]
  }, {
    path: "/error",
    component: _8c5bbc80,
    name: "error___en"
  }, {
    path: "/messages",
    component: _38ab8e78,
    name: "messages___en",
    children: [{
      path: ":id?",
      component: _9d4ef722,
      name: "messages-id___en"
    }]
  }, {
    path: "/redeem",
    component: _e2e6fdd8,
    name: "redeem___en"
  }, {
    path: "/redirecting",
    component: _7510cb3e,
    name: "redirecting___en"
  }, {
    path: "/sellers",
    component: _5d67e28c,
    name: "sellers___en"
  }, {
    path: "/support",
    component: _b8bc67f2,
    name: "support___en"
  }, {
    path: "/kyc/pending",
    component: _a0929116,
    name: "kyc-pending___en"
  }, {
    path: "/kyc/redirecting",
    component: _f0eae438,
    name: "kyc-redirecting___en"
  }, {
    path: "/legal/terms",
    component: _ff17842e,
    name: "legal-terms___en"
  }, {
    path: "/market/preview",
    component: _25b64bed,
    name: "market-preview___en",
    children: [{
      path: "collections",
      component: _4d09f91a,
      name: "market-preview-collections___en"
    }, {
      path: "favorites",
      component: _4e0ed0b5,
      name: "market-preview-favorites___en"
    }, {
      path: "marketplace",
      component: _8ec5c5ae,
      name: "market-preview-marketplace___en"
    }, {
      path: "mybids",
      component: _7f3c0a58,
      name: "market-preview-mybids___en"
    }, {
      path: "myofferings",
      component: _3fa66642,
      name: "market-preview-myofferings___en"
    }, {
      path: "assets/related/:involved?",
      component: _2d0157c8,
      name: "market-preview-assets-related-involved___en"
    }, {
      path: "assets/:collection?",
      component: _175a3143,
      name: "market-preview-assets-collection___en"
    }]
  }, {
    path: "/my-tokens/mint-form",
    component: _7358b55a,
    name: "my-tokens-mint-form___en"
  }, {
    path: "/resources/image",
    component: _9fefefae,
    name: "resources-image___en"
  }, {
    path: "/session/forgot",
    component: _0894a434,
    name: "session-forgot___en"
  }, {
    path: "/session/login",
    component: _4739eb30,
    name: "session-login___en"
  }, {
    path: "/session/signup",
    component: _074fa0ae,
    name: "session-signup___en"
  }, {
    path: "/stripe/onboarding/minter",
    component: _3c32728e,
    name: "stripe-onboarding-minter___en"
  }, {
    path: "/stripe/payment/cancel",
    component: _ed55fc46,
    name: "stripe-payment-cancel___en"
  }, {
    path: "/stripe/payment/refresh",
    component: _a22fa8f0,
    name: "stripe-payment-refresh___en"
  }, {
    path: "/stripe/payment/success",
    component: _93fff6a0,
    name: "stripe-payment-success___en"
  }, {
    path: "/market/collection/:id?",
    component: _ebbc3a98,
    name: "market-collection-id___en"
  }, {
    path: "/market/product/:id",
    component: _2c1d0882,
    name: "market-product-id___en"
  }, {
    path: "/my-tokens/list-form/:id?",
    component: _fc2b727c,
    name: "my-tokens-list-form-id___en"
  }, {
    path: "/password/reset/:token?",
    component: _50f13166,
    name: "password-reset-token___en"
  }, {
    path: "/market/product/:id?/checkout",
    component: _65f1a074,
    name: "market-product-id-checkout___en"
  }, {
    path: "/",
    component: _760c038a,
    name: "index___en"
  }, {
    path: "/:usernick",
    component: _10c28c45,
    name: "usernick___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
